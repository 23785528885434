import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, concatMap, delay, delayWhen, forkJoin, mergeMap, of, retry, take, timer } from 'rxjs';
import { DetallePreFactura, GeneratePreInvoiceRequest, PreFactura, PreInvoiceDetailDocument, PreInvoiceDocument, PreInvoiceParams, PreInvoiceRequest, UpdatePreInvoiceRequest } from 'src/app/domain/interfaces/pre-invoice.interface';
import { SuccessResponse } from 'src/app/domain/interfaces/response-base.interface';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { PreInvoiceService } from 'src/app/domain/servicios/pre-invoice.service';
import { environment as env } from "src/environments/environment"

@Component({
  selector: 'app-aceptar-prefactura',
  templateUrl: './aceptar-prefactura.component.html',
  styleUrls: ['./aceptar-prefactura.component.css']
})
export class AceptarPrefacturaComponent implements OnInit {

  title:string = '';
  content:string = '';
  rechazar: boolean = false;
  preInvoiceParams!: PreInvoiceParams;
  preInvoiceData!: PreFactura;
  approvalForm!: FormGroup;
  usuario: any;
  reference!: string;
  note!: string;
  periodo: string = '';
  requiredSubtotal!: number;
  applyRounding = false;
  applyReference = false;
  regenerateType = '';

  constructor(private alert: AlertsService,
              private fb: FormBuilder,
              private preInvoiceService : PreInvoiceService,
              private router: Router
            ) { }

  ngOnInit(): void {

    this.approvalForm = this.fb.group({
      reference: ['', Validators.required],
      requiredSubtotal: ['', Validators.required],
      note: ['', Validators.minLength(8)]
    });

    this.validateLink();
  }

  validateLink() {
    this.alert.showInvoiceSpinner('Validando link de prefactura, por favor espere.');
  
    const makeRequest = (attempts: number = 1) => {
      this.preInvoiceService.validarLinkPrefactura(this.preInvoiceParams.idPrefactura).subscribe({
        next: (response: any) => {
          this.preInvoiceService.obtenerPrefactura(this.preInvoiceParams.idPrefactura)
            .subscribe({
              next: (res: SuccessResponse) => {
                this.preInvoiceData = res.data.preinvoice;
                this.periodo = this.preInvoiceData.periodo;
                this.applyRounding = this.preInvoiceData.aplicaRedondeo;
                this.applyReference = this.preInvoiceData.aplicaReferencia;
  
                console.log('Datos de prefactura: ', this.preInvoiceData);
  
                this.checkVisibilityAndValidation();
                this.alert.closesSpiner();
              },
              error: (error: any) => {
                this.alert.closesSpiner();
                console.error('error consultando datos de prefactura', error);
                this.alert.showError('Error obteniendo datos, por favor intente más tarde.', false, 'Error', false, '', 'Aceptar', false);
              }
            });
  
          console.log(response);
        },
        error: (error: any) => {
          if (attempts < 3) {
            console.log('Reintentando... Intento número: ', attempts + 1);
            setTimeout(() => makeRequest(attempts + 1), 2000);
          } else {
            this.alert.closesSpiner();
            console.error('error validando link', error);
            this.alert.showError(
              'Error validando datos de prefactura. ' + error?.error?.message,
              false,
              'Error validación',
              false,
              env.urlWebCerti,
              'Aceptar',
              true
            );
          }
        }
      });
    };
    makeRequest();
  }

  acceptPreInvoice(){
    
    console.log(this.preInvoiceParams);
    this.alert.showInvoiceSpinner('Generando prefactura, este proceso podría tardar algunos segundos.');

    let rawValue = this.approvalForm.get('requiredSubtotal')?.value;
    let valueWithoutDots = rawValue.replace(/\./g, ''); 
    let subTotal = parseFloat(valueWithoutDots)

    const preInvoiceRequest: PreInvoiceRequest = {
      idPrefacturaOld: this.preInvoiceData.idPrefactura,
      nitCliente: this.preInvoiceData.nitCliente,
      idTyC: this.preInvoiceData.idTyC,
      periodo: this.preInvoiceData.periodo,
      divisa: this.preInvoiceData.divisa,
      subtotalRequerido: subTotal || 0,
      referencia: this.approvalForm.get('reference')?.value || '',
      usuario: 'activo.digital',
      detalle: this.preInvoiceData.detalle
    };

    // 1: Conciliación, 2: Redondeo, 3: Orden Reference
    if(this.applyRounding && this.applyReference){
      this.regenerateType = '2'
    } else if (this.applyRounding){
      this.regenerateType = '2'
    } else if (this.applyReference) {
      this.regenerateType = '3'
    } else {
      this.regenerateType = '1'
    }

    let confirmationMessage = `¡Gracias por su confirmación!
    Hemos registrado su aceptación de la prefactura enviada. 
    Gracias por confiar y preferirnos.`;

    if (this.regenerateType === '2' || this.regenerateType === '3') {

      console.log('request generacion: ', preInvoiceRequest);
    
      this.regeneratePreInvoice(preInvoiceRequest, this.regenerateType, this.preInvoiceData.aplicaReferencia).subscribe({
        next: (res: SuccessResponse) => {
          this.alert.closesSpiner();
          console.log('Respuesta proceso regeneracion: ', res);
          confirmationMessage = `¡Gracias por su confirmación! 
          Hemos registrado su aceptación de la prefactura enviada. 
          Procederemos con la generación de la factura correspondiente, la cual se enviará por el mismo mecanismo. 
          Gracias por confiar y preferirnos.`;

          this.handlePreInvoiceResponse(confirmationMessage, 'Error al procesar la prefactura');
        },
        error: (error) => {
          this.handlePreInvoiceError(error);
        }
      });
    } else if(this.regenerateType === '1') {

      const requestAccept = {
        idPrefactura: this.preInvoiceData.encryptIdPrefactura
      };
      
      console.log('request accept: ', requestAccept);
      
      const updateRequest: UpdatePreInvoiceRequest = {
        idPrefactura: this.preInvoiceData.encryptIdPrefactura,
        referencia: '',
        observaciones: this.approvalForm.get('note')?.value,
        usuario: 'activo.digital'
      };
      
      this.preInvoiceService.actualizarPrefactura(updateRequest)
        .pipe(
          concatMap((updateResponse: SuccessResponse) => {
            console.log('Prefactura actualizada:', updateResponse);
      
            return this.preInvoiceService.aceptarPrefactura(requestAccept).pipe(
              mergeMap((response) => {
                return of(response);
              }),
              catchError((error) => {
                return timer(30000).pipe(
                  mergeMap(() => {
                    return this.preInvoiceService.aceptarPrefactura(requestAccept);
                  })
                );
              }),
              take(4)
            );
          })
        )
        .subscribe({
          next: (res: SuccessResponse) => {
            console.log('Respuesta aceptacion:', res);
            this.handlePreInvoiceResponse(confirmationMessage, 'Error al procesar la prefactura');
          },
          error: (error: any) => {
            console.log('error aceptacion:', error);
            this.handlePreInvoiceError(error);
          }
        });
    }
  }

  private regeneratePreInvoice(preInvoiceRequest: any, regenerateType: string, update = false) {
    console.log('Datos para regeneración prefactura: ', this.preInvoiceData);

    const request: any = {
      idPrefactura: this.preInvoiceData.encryptIdPrefactura,
      referencia: this.approvalForm.get('reference')?.value,
      observaciones: this.approvalForm.get('note')?.value,
      usuario: 'activo.digital'
    };

    let idPrefacturaEncrypt = '';

    if (this.applyRounding && this.applyReference) {
      return this.preInvoiceService.crearPrefactura(preInvoiceRequest).pipe(
        concatMap((res: SuccessResponse) => {
          console.log('Prefactura creada:', res);

          idPrefacturaEncrypt = res?.data?.idPrefactura?.toString();

          const updateRequest: UpdatePreInvoiceRequest = {
            idPrefactura: idPrefacturaEncrypt, // Usamos el ID de la prefactura creada
            referencia: this.approvalForm.get('reference')?.value,
            observaciones: this.approvalForm.get('note')?.value,
            usuario: 'activo.digital'
          };
          return this.preInvoiceService.actualizarPrefactura(updateRequest);
        }),
        concatMap((updateRes: SuccessResponse) => {
          console.log('Prefactura actualizada:', updateRes);

          const generatePreInvoiceRequest: GeneratePreInvoiceRequest = {
            idPreInvoiceEncrypt: idPrefacturaEncrypt,
            typeRegenerate: regenerateType,
            userName: 'activo.digital'
          };

          return this.preInvoiceService.regenerarPrefactura(generatePreInvoiceRequest).pipe(
            concatMap((regenRes: SuccessResponse) => {
              console.log('Prefactura regenerada:', regenRes);

              const requestAccept = {
                idPrefactura: idPrefacturaEncrypt
              };

              console.log('request accept: ', requestAccept);
              return this.preInvoiceService.aceptarPrefactura(requestAccept).pipe(
                mergeMap((response) => {
                  return of(response);
                }),
                catchError((error) => {
                  return timer(30000).pipe(
                    mergeMap(() => {
                      return this.preInvoiceService.aceptarPrefactura(requestAccept);
                    })
                  );
                }),
                take(4)
              );
            })
          );
        })
      );
    } else {
      return this.preInvoiceService[update ? 'actualizarPrefactura' : 'crearPrefactura'](update ? request : preInvoiceRequest).pipe(
        concatMap((res: SuccessResponse) => {
          console.log(update ? 'Prefactura actualizada:' : 'Prefactura creada:', res);
      
          const generatePreInvoiceRequest: GeneratePreInvoiceRequest = {
            idPreInvoiceEncrypt: update ? this.preInvoiceData.encryptIdPrefactura : res.data.idPrefactura.toString(),
            typeRegenerate: regenerateType,
            userName: 'activo.digital'
          };
      
          return this.preInvoiceService.regenerarPrefactura(generatePreInvoiceRequest).pipe(
            concatMap((regenRes: SuccessResponse) => {
              console.log('Prefactura regenerada:', regenRes);
      
              const requestAccept = {
                idPrefactura: this.preInvoiceData.encryptIdPrefactura
              };
      
              console.log('request accept: ', requestAccept);
      
              return this.preInvoiceService.aceptarPrefactura(requestAccept).pipe(
                mergeMap((response) => {
                  return of(response);
                }),
                catchError((error) => {
                  return timer(30000).pipe(
                    mergeMap(() => {
                      return this.preInvoiceService.aceptarPrefactura(requestAccept);
                    })
                  );
                }),
                take(4)
              );
            })
          );
        })
      );
    }
  }

  private handlePreInvoiceResponse(successMessage: string, errorMessage: string) {
    this.alert.closesSpiner();
    let confirmacion = this.alert.showConfirmacion(successMessage, 'Confirmación de Aceptación', 'Aceptar', 'Cancelar');
    confirmacion.result.then((confirmacion: any) => {
      console.log("alertaConfirmacion", confirmacion);
      if (confirmacion) {
        window.location.href = env.urlWebCerti;
      }
    });
  }

  private handlePreInvoiceError(error: any) {
    console.error('Error generando o regenerando la prefactura:', error);
    this.alert.closesSpiner();
    this.alert.showError(
      `Error generando la prefactura. ${error?.error?.message}`,
      false,
      'Error',
      false,
      '',
      'Aceptar',
      false
    );
  }  

  declinePreInvoice(){
    this.alert.showRechazarPrefactura(this.preInvoiceData);
  }

  closeModal(){
    this.alert.closeAceptarPrefactura();
  }

  camposvalidos(campo: any) {
    return this.approvalForm.get(campo)!.invalid &&
      (this.approvalForm.get(campo)!.touched || this.approvalForm.get(campo)!.dirty);
  }

  previewPreInvoice(){

    if(this.approvalForm.invalid){
      this.approvalForm.markAllAsTouched();
    } else {

      this.alert.showInvoiceSpinner('Visualizando prefactura, este proceso podría tardar algunos segundos.');

      this.reference = this.approvalForm.get('reference')?.value;

      const preInvoice: PreInvoiceDocument = {
        idPreInvoice: this.preInvoiceData.idPrefactura,
        paymentTerms: this.preInvoiceData.condicionPago,
        salespersonName: this.preInvoiceData.nombreDelComercial,
        invoicePeriod: this.preInvoiceData.periodo,
        nameClient: this.preInvoiceData.nombreCliente,
        nitClient: this.preInvoiceData.nitCliente,
        nitParents: this.preInvoiceData.nitClientePadre,
        department: this.preInvoiceData.codDepartamento,
        city: this.preInvoiceData.codCiudad,
        direction: this.preInvoiceData.direccion,
        phone: this.preInvoiceData.telefono,
        regimen: this.preInvoiceData.regimen,
        currency: this.preInvoiceData.divisa,
        observations: this.reference,
        preInvoiceDetails: this.mapToPreInvoiceDetail()
      };
  
      console.log('datos prefactura: ', preInvoice);
  
      this.preInvoiceService.generarPdfPrefactura(preInvoice)
      .subscribe({
        next: (res: SuccessResponse) => {
  
          this.alert.closesSpiner();

          const binaryString = atob(res.data.pdfB64);
          const length = binaryString.length;
          const bytes = new Uint8Array(length);
        
          for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i); 
          }
        
          const blob = new Blob([bytes], { type: 'application/pdf' });
        
          const urlBlob = window.URL.createObjectURL(blob);
          this.alert.showpOpenPFD(urlBlob);
        },
        error: (error: any) => {
          this.alert.closesSpiner();
          this.alert.showError(
            'Error previsualizando archivo PDF ' + error?.error?.message, 
            false, 
            'Error validación', 
            false, 
           '',
            'Aceptar',
            false);
        }
      });

    }
  }

  viewGeneratedPreInvoice(){
    
    this.alert.showInvoiceSpinner('Cargando prefactura, por favor espere.');

    this.preInvoiceService.visualizarDocumentoPreFactura(this.preInvoiceData.pathBucket).subscribe({
      next: (blob: Blob) => {
        const urlBlob = window.URL.createObjectURL(blob);
        this.alert.showpOpenPFD(urlBlob);
        this.alert.closesSpiner(); 
      },
      error: (error: any) => {
        this.alert.closesSpiner();  

        console.error('Error al visualizar el archivo.', error);
        this.alert.showError(
          'Error visualizando archivo PDF ' + error?.error?.message, 
          false, 
          'Error', 
          false, 
         '',
          'Aceptar',
          false);
      }
    });
  }

  mapToPreInvoiceDetail() {
    return this.preInvoiceData.detalle.map((detalle, index) => ({
      line: (index + 1).toString(),
      description: `Producto ${detalle.idSubProducto}`,
      quantity: detalle.cantidad.toString(),
      unitPrice: detalle.tarifa.toString(),
      subtotal: detalle.subtotal.toString(),
    }));
  }


  checkVisibilityAndValidation() {
    if (this.applyRounding) {
      this.approvalForm.get('requiredSubtotal')?.setValidators([Validators.required, Validators.minLength(3)]);
    } else {
      this.approvalForm.get('requiredSubtotal')?.clearValidators();
    }

    if (this.applyReference) {
      this.approvalForm.get('reference')?.setValidators([Validators.required, Validators.maxLength(100)]);
    } else {
      this.approvalForm.get('reference')?.clearValidators();
    }

    this.approvalForm.get('requiredSubtotal')?.updateValueAndValidity();
    this.approvalForm.get('reference')?.updateValueAndValidity();
  }

/**
 * Convierte un texto en formato contable con puntos como separadores de miles
 * y coma como separador decimal. Si no hay parte decimal, agrega ",00".
 * @param event 
 */
formatAccountValue(event: any) {
  let value = event.target.value;

  // Eliminar caracteres no numéricos, puntos y comas
  value = value.replace(/[^\d]/g, '');

  if (value.indexOf('.') !== -1) {
    const [integerPart, decimalPart] = value.split('.');
    const formattedInteger = this.formatInteger(integerPart);
    const formattedDecimal = decimalPart.length > 2 ? decimalPart.slice(0, 2) : decimalPart;

    value = formattedInteger + '.' + formattedDecimal;
  } else {
    value = this.formatInteger(value);
  }

  event.target.value = value;
}

/**
 * Formatea la parte entera de un número con puntos como separadores de miles
 * @param integer 
 */
formatInteger(integer: string): string {
  return integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}


}
