import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-preinvoice-loading',
  templateUrl: './preinvoice-loading.component.html',
  styleUrls: ['./preinvoice-loading.component.css']
})
export class PreinvoiceLoadingComponent implements OnInit {

  title: string = '';

  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show()
  }

  ngOnDestroy(): void{
    this.spinner.hide()
  }
}
