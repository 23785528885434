import { Component, Input, OnInit} from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { PreInvoiceService } from 'src/app/domain/servicios/pre-invoice.service';
import { LogService } from '../../../../domain/servicios/log.service';
import { PreFactura} from 'src/app/domain/interfaces/pre-invoice.interface';
import { SuccessResponse } from 'src/app/domain/interfaces/response-base.interface';

@Component({
  selector: 'app-rechazar-prefactura',
  templateUrl: './rechazar-prefactura.component.html',
  styleUrls: ['./rechazar-prefactura.component.css']
})
export class RechazarPrefacturaComponent implements OnInit {

  @Input() preInvoiceData!: PreFactura;
  declineForm!: FormGroup;
  usuario: any = {nombre:''};
  nombreString!:string;
  nombre!: string;

  constructor(private fb: FormBuilder,
    private preInvoiceService: PreInvoiceService,
    private logger: LogService,
    private alert: AlertsService
  ) { }

  ngOnInit(): void {
    this.declineForm = this.fb.group({
      nombre: ['', Validators.required],
      tipo: ['', Validators.required],
      numero: ['', Validators.required],
      cargo: ['', Validators.required],
      tipoRechazo: ['', Validators.required],
      motivoRechazo: ['', Validators.required]
    });
  }

  /*ingreso y validacion de datos de formulario*/
  ngsubmit() {
    this.alert.showInvoiceSpinner('Se esta realizando el rechazo de la prefactura, por favor espere.');
    let { nombre, tipo, numero, cargo, tipoRechazo, motivoRechazo } = this.declineForm.value;
    let request = {
      idPrefactura: this.preInvoiceData.idPrefactura,
      nombreCompleto: nombre,
      tipoIdentificacion: tipo,
      numeroIdentificacion: numero,
      cargo: cargo,
      tipoRechazo: tipoRechazo,
      motivoRechazo: motivoRechazo
    }
    this.preInvoiceService.rechazarPrefactura(request)
      .subscribe({
        next: (res: SuccessResponse) => {
          this.alert.closesSpiner()
          this.logger.log("respuesta formularioRechazo", res)
          this.alert.showFinishRechazarPrefactura(this.preInvoiceData.periodo);
        },
        error: (error: any) => {
          this.alert.closesSpiner()
          console.error('Error en subscriber formularioRechazo', error)
          this.alert.showError(error.mensaje, true, null, false, null, null, false)
        }
      })
  }

  camposvalidos(campo: any) {
    return this.declineForm.get(campo)!.invalid &&
      (this.declineForm.get(campo)!.touched || this.declineForm.get(campo)!.dirty);
  }

  close(){
    this.alert.closesFiels();
  }
}
