import { environment as env } from "src/environments/environment"

export const urlRequest = {
    //TODO:listas negras servicios
    //TODO:listas negras servicios
    TOKEN:`${ env.tokenUrlWS }/oauth2/token`,
    LISTPJ : `${ env.listasEC2  }/api/pj/listasnegras`,
    LISTPN : `${ env.listasEC2 }/api/pn/listasnegras`,
    ASEPTPN:`${ env.listasEC2 }/api/pn/aceptacion`,
    ASEPTPJ:`${ env.listasEC2 }/api/pj/aceptacion`,
    FORMUARIOCONTACTO:`${ env.gatewayAWS }/formularioContacto`,    
    HISTORICO:`${ env.gatewayAWS }/historico/personas`,
    OBTENERIP: 'https://jsonip.com',    
    URLPSE: `${ env.gatewayAWS }/pasarelaPago/url`,
    URLESTADOPSE: `${ env.gatewayAWS }/pasarelaPago/estadoTransaccion`,
    CREARORDENPN: `${ env.ordenPedidoServiceEC2 }/personaNatural`,
    ACTUALIZARORDENPN: `${ env.ordenPedidoServiceEC2 }/personaNatural/`,
    CREARORDENPJ: `${ env.ordenPedidoServiceEC2 }/personaJuridica`,
    ACTUALIZARORDENPJ: `${ env.ordenPedidoServiceEC2 }/personaJuridica/`,
    DESCARGARORDENPAGO: `${ env.ordenPedidoServiceEC2 }/ordenPedido/pdfPago/`,
    GENERARREQUESTJURIDICO: `${ env.ordenPedidoServiceEC2 }/generador/generarRequestCSR`,
    URLCARGARORDENPAGO: `${ env.ordenPedidoServiceEC2 }/ordenPedido/cargarPago/`,
    URLAPLICARCODIGODESCUENTO: `${ env.ordenPedidoServiceEC2 }/ordenPedido/aplicar-cupon`,
    URLVALIDARCODIGODESCUENTO: `${ env.ordenPedidoServiceEC2 }/ordenPedido/validar-cupon`,
    URLELIMINARCODIGODESCUENTO: `${ env.ordenPedidoServiceEC2 }/ordenPedido/eliminar-cupon/`,
    URLCONSULTARORDEN: `${ env.ordenPedidoServiceEC2 }/ordenPedido/consultar`,
    URLCONSULTARULTIMAORDEN: `${ env.ordenPedidoServiceEC2 }/ordenPedido/ultimaOrden/`,
    VALIDACIONPREGUNTAS: `${ env.validacionIdentidadServiceEC2 }/validacion-identidad/preguntas`,
    VALIDACIONRESPUESTAS: `${ env.validacionIdentidadServiceEC2 }/validacion-identidad/respuestas`,
    OBTENERCERTIFICADO:`${ env.certificadoServiceEC2 }/certificado/obtener`,
    UNIRLLAVES: `${ env.gatewayAWS }/APPGenerate/JoinKeys`,
    GENERARREQUESTLLAVE: `${ env.ordenPedidoServiceEC2 }/generador/generarLlavePrivada`,
    INTENTOSVALIDACION: `${ env.validacionIdentidadServiceEC2 }/validacion-identidad/consultar-intentos`,
    GUARDARINTENTOVALIDACION: `${ env.validacionIdentidadServiceEC2 }/validacion-identidad/intento`,
    VALIDARLINKPREFACTURA: `${ env.gatewayFact }/fact-automatizada/prefactura/validar`,
    CONSULTARPREFACTURA: `${ env.consumosServiceEC2 }/fact-automatizada/prefactura/consultar`,
    APROBARPREFACTURA: `${ env.gatewayFact }/fact-automatizada/prefactura/aprobar`,
    RECHAZARPREFACTURA: `${ env.gatewayFact }/fact-automatizada/prefactura/rechazar`,
    CREARPREFACTURA: `${ env.consumosServiceEC2 }/fact-automatizada/prefactura/crear`,
    ACTUALIZARPREFACTURA: `${ env.consumosServiceEC2 }/fact-automatizada/prefactura/actualizar`,
    GENERARPDFPREFACTURA: `${ env.prefacturaServiceEC2 }/prefactura/pdfPrefactura`,
    REGENERARPREFACTURA: `${ env.prefacturaServiceEC2 }/prefactura/genera-prefactura`,
    DOWNLOAD_FILE: `${ env.gatewayDownloadFile }/Descarga`,
    CONSULTARSOLICITANTEREVOCACION: `${ env.certificadoServiceEC2 }/revocacion/solicitante`,
    MOTIVOSRECHAZOREVOCACION: `${ env.certificadoServiceEC2 }/revocacion/motivos`,
    CERTIFICADOSREVOCACION: `${ env.certificadoServiceEC2 }/revocacion/listar`,
    DETALLECERTIFICADOREVOCACION: `${ env.certificadoServiceEC2 }/revocacion/detalle`,
    REVOCARCERTIFICADO: `${ env.certificadoServiceEC2 }/revocacion/revocar`,
    CAPCHA: `${env.captchaEC2}/api/obtener-captcha`,
    OTPREQUEST: `${env.gatewayAWSOtp}/Stage/ws/rest/sendOTP`,
    CONSULTARFACTURA: `${ env.gatewayAWS }/descargaFactura`,
    CONSULTARSOLICITUDESESTADOTRAMITE: `${ env.estadoTramiteServiceEC2 }/estado-solicitud/listar`,
    URLEBIOMETRICS: `${ env.apiEBiometrics }/`
};

export const tokenSolicitud = {
    grant_type: 'client_credentials',
    client_id: `${ env.tokenClientId }`,
    scope: `${ env.tokenScope }`,
    client_secret: `${ env.tokenSecret }`
};

export const datosPasarela = {
    urlRetorno: "",
    ipUsuario: "",
    navegador: "",
    pasarela: `${ env.pasarela}`
}