<div class="modal-header">
  <h5 class="textomodal lh-1">
    Estás a punto de entrar al mundo de las firmas digitales.
  </h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<form>
  <div class="modal-body">
    <div class="margen1 animate__animated animate__fadeIn animate__fast">
      <div class="d-flex justify-content-center">
        <button type="submit" style="margin-left: 0;" class="btn bolistas" [ngClass]="{'btn-primary': personPn, 'btn-secondary': !personPn}"
          (click)="statusPn()">
          <i class="fa-regular fa-user"></i>&nbsp;Persona natural
        </button>

        <button type="submit" style="margin-right: 0;" class="btn bolistas" [ngClass]="{'btn-primary': personPj, 'btn-secondary': !personPj}"
          (click)="statusPj()">
          <i class="fa-regular fa-handshake"></i>&nbsp;Entidad/Empresa
        </button>
      </div>
    </div>
  </div>


  <ng-template [ngIf]="personPj">
    <app-persona-j></app-persona-j>
  </ng-template>

  <ng-template [ngIf]="personPn">

    <app-persona-n></app-persona-n>
  </ng-template>
</form>