import { Component, Input, OnInit } from '@angular/core';
import { environment as env } from "src/environments/environment";

@Component({
  selector: 'app-finaliza-rechazo',
  templateUrl: './finaliza-rechazo.component.html',
  styleUrls: ['./finaliza-rechazo.component.css']
})
export class FinalizaRechazoComponent implements OnInit {

  @Input() periodo: string = '';
  constructor() { }

  ngOnInit(): void {
  }

  close(){
    let externalUrl = `${env.urlWebCerti}`;
    localStorage.clear()
    window.location.replace(externalUrl);
  }
}
