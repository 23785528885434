import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PersonaNComponent } from './validaciones/persona-n/persona-n.component';
import { ValidacionIdentidadComponent } from './validacion-identidad/validacion-identidad.component';
import { PersonaJComponent } from './validaciones/persona-j/persona-j.component';
import { ContactenosComponent } from './contactenos/contactenos.component';
import { FlujocomprasspsComponent } from './flujocomprassps/flujocomprassps.component';
import { InfoBasicaComponent } from './flujocomprassps/info-basica/info-basica.component';
import { EntregaComponent } from './flujocomprassps/entrega/entrega.component';
import { DatosFacturacionComponent } from './flujocomprassps/datos-facturacion/datos-facturacion.component';
import { DatosempresaComponent } from './flujocomprassps/datosempresa/datosempresa.component';
import { SolicitantesComponent } from './flujocomprassps/solicitantes/solicitantes.component';
import { ValidacionesComponent } from './validaciones/validaciones.component';
import { PersonaSolicitanteComponent } from './validaciones/persona-solicitante/persona-solicitante.component';
import { ContactoComponent } from './flujocomprassps/contacto/contacto.component';
import { NocopiadoDirective } from '../directivas/nocopiado.directive';
import { InputMaskDirective } from '../directivas/input-mask.directive';
import { UppercaseDirective } from '../directivas/uppercase.directive';
import { LoadingComponent } from './loading/loading.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { MessageComponent } from './message/message.component';
import { CertimailHomeComponent } from 'src/app/modulos/certimail/certimail-home/certimail-home.component';
import { SspsComponent } from './ssps/ssps.component';
import { BarraComponent } from './barra/barra.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PSEComponent } from './flujocomprassps/pse/pse.component';
import { PagoComponent } from './flujocomprassps/pago/pago.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FinalizadoComponent } from 'src/app/ui/components/finalizado/finalizado.component';
import { SolicitudFinalizadaComponent } from 'src/app/ui/components/solicitudfinalizada/solicitudfinalizada.component';
import { AlertaSalirValidaIdentidadComponent } from 'src/app/ui/components/alerta-salir-valida-identidad/alerta-salir-valida-identidad.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { SolicitudFaltantesComponent } from '../../ui/components/solicitudfaltantes/solicitudfaltantes.component';
import { DescargaCertificadoComponent } from './descarga-certificado/descarga-certificado.component';
import { DescargaCertificadoModalComponent } from 'src/app/ui/components/descarga-certificado-modal/descarga-certificado-modal.component';
import { DescargaPfxComponent } from 'src/app/ui/components/descarga-pfx/descarga-pfx.component';
import { AceptarPrefacturaComponent } from './prefactura/aceptar-prefactura/aceptar-prefactura.component';
import { RechazarPrefacturaComponent } from './prefactura/rechazar-prefactura/rechazar-prefactura.component';

import { RevocarCertificadoComponent } from './revocar-certificado/revocar-certificado.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { DescargarFacturaComponent } from './descargar-factura/descargar-factura.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ShowOpenTabsComponent } from './showopentabs/show-open-tabs.component';
import { PreinvoiceLoadingComponent } from './prefactura/preinvoice-loading/preinvoice-loading.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { FinalizaRechazoComponent } from './prefactura/rechazar-prefactura/finaliza-rechazo/finaliza-rechazo.component';
import { EstadoTramiteComponent } from './estado-tramite/estado-tramite.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';

@NgModule({
  declarations:[
    NocopiadoDirective,
    UppercaseDirective,
    InputMaskDirective,
    LoadingComponent,
    ConfirmationComponent,
    MessageComponent,
    CertimailHomeComponent,
    SspsComponent,
    SolicitudFaltantesComponent,
    PersonaNComponent,
    ValidacionIdentidadComponent,
    PersonaJComponent,
    ContactenosComponent,
    FlujocomprasspsComponent,
    InfoBasicaComponent,
    EntregaComponent,
    DatosFacturacionComponent,
    DatosempresaComponent,
    SolicitantesComponent,
    ValidacionesComponent,
    PersonaSolicitanteComponent,
    ContactoComponent,
    BarraComponent,
    PSEComponent,
    PagoComponent,
    FinalizadoComponent,
    SolicitudFinalizadaComponent,
    AlertaSalirValidaIdentidadComponent,
    DescargaCertificadoComponent,
    DescargaCertificadoModalComponent,
    DescargaPfxComponent,
    AceptarPrefacturaComponent,
    RechazarPrefacturaComponent,
    RevocarCertificadoComponent,
    DescargarFacturaComponent,
    ShowOpenTabsComponent,
    PreinvoiceLoadingComponent,
    PdfViewerComponent,
    FinalizaRechazoComponent,
    EstadoTramiteComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule, 
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgOtpInputModule,
    PdfViewerModule,
    MatTableModule, MatButtonModule, MatIconModule, NgStepperModule, CdkStepperModule
  ]
  ,exports:[
    ValidacionesComponent,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    InputMaskDirective,
    ShowOpenTabsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModulosModule { }
