<div class="modal-header" #modalHeader>
    <h5 class="textomodal"
    >Visor de documentos PDF</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="cerrar()"></button>
  </div>
  <div class="modal-body">
        <div class="marco">
        <div class="mb-3 ps-5 pe-5">
            <iframe #pdfViewer frameborder="0" width="100%" height="800px"></iframe>
        </div>
  </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button 
     type="button"
    class="btn btn-primary"
    (click)="cerrar()"
   >Aceptar</button>