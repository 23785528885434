import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { SspsAdapterService } from 'src/app/infraestucture/driven-adapter/ssps-adapter.service';
import { LogService } from './log.service'
import { v4 as uuidv4 } from 'uuid';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { environment as env } from "src/environments/environment"
import { AlertsService } from './alerts.service';
const crypto = require('crypto');

@Injectable({
  providedIn: 'root'
})
export class SspsService {
  ip = ""
  reintentosIp = 0;
  // declaracion de sujeto para multiples observables  Fuentes de cadenas observables
  private envios = new Subject<any>();
  // Flujos de cadenas observables
  // Observable de tipo string 
  eventos$ = this.envios.asObservable();
  // Comandos de mensajes de servicio
  public compras: any[] = [];
  public reposiciones: any[] = [];
  public typePerson: string = '';
  public person: any;
  public numero: string = '';
  private readonly channel = new BroadcastChannel(env.urlVentaDigital);
  constructor(private http: HttpClient,
    private ssps: SspsAdapterService,
    private componentesService: ComponentesService,
    private logger: LogService,
    private alert: AlertsService) {
    this.LocalStorage();
    this.setupCommunication()
  }

  /*emicion de eventos flujo de compra*/
  eventos(change: any) {
    this.envios.next(change);
  }

  encrypt(data: any): any {
    if (data !== null && data !== undefined) {
      var saltStr = this.generateSalt32Byte()
      const digest = 'sha256';
      const key = crypto.pbkdf2Sync(`${env.cipher}`, saltStr, 1000, 32, digest); //key len 32bytes i.e 256bits
      const iv = this.getRandomIV();
      const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);

      let encrypted = cipher.update(data, 'utf8', 'hex');
      encrypted += cipher.final('hex');

      const tag = cipher.getAuthTag();
      const output = Buffer.from(iv.toString('hex') + saltStr.toString('hex') + encrypted + tag.toString('hex'), 'hex').toString('base64');
      return output
    }
  }

  async sha256(message: string): Promise<string> {
    return crypto
      .createHash('sha256')
      .update(message)
      .digest('hex');;
  }


  decryptUsingAES256(encryptedData: any) {
    if (encryptedData !== null && encryptedData !== undefined) {
      let ciphertextAndNonceAndSalt = Buffer.from(encryptedData, 'base64');
      let nonce = ciphertextAndNonceAndSalt.subarray(0, 12);
      let saltAndCipherText = ciphertextAndNonceAndSalt.slice(12);
      // Create buffers of salt and ciphertextAndNonce.
      let salt = saltAndCipherText.subarray(0, 16);
      // Derive the key using PBKDF2.
      const digest = 'sha256';
      const key = crypto.pbkdf2Sync(`${env.cipher}`, salt, 1000, 32, digest);

      // Create buffers of nonce, ciphertext and tag.

      let ciphertext = saltAndCipherText.subarray(16, saltAndCipherText.length - 16);

      // Create the cipher instance.
      let cipher = crypto.createDecipheriv('aes-256-gcm', key, nonce);
      let tag = saltAndCipherText.subarray(ciphertext.length + 16);

      // Decrypt and return result.
      cipher.setAuthTag(tag);
      let decrypted = Buffer.concat([cipher.update(ciphertext), cipher.final()]).toString()
      return decrypted;
    }

    return ""
  }

  getRandomIV() {
    return crypto.randomBytes(12);
  }

  generateSalt32Byte() {
    return crypto.randomBytes(16);
  }


  getpreguntas(person: string) {
    //return this.http.get(`${ this.url}/preguntas`);
    return this.ssps.EXshowTask(person);
  }

  saveLocalStorage(key: any, content: any) {
    localStorage.setItem(key, this.encrypt(content));
  }

  getLocalStorage(key: any) {
    return localStorage.getItem(key) !== null && localStorage.getItem(key) !== undefined ?
      this.decryptUsingAES256(localStorage.getItem(key)) : null
  }

  token() {
    return this.ssps.token();
  }

  politicas() {
    // return this.http.get(`${ this.url}/politicas`);
    return this.ssps.politicaExt();
  }

  tiposDireccion() {
    // return this.http.get(`${ this.url}/politicas`);
    return this.ssps.tiposDireccionExt();
  }

  tiposRegimenTributario() {
    // return this.http.get(`${ this.url}/politicas`);
    return this.ssps.tiposRegimenTributarioExt();
  }

  cargarProfesiones() {
    // return this.http.get(`${ this.url}/politicas`);
    return this.ssps.cargarProfesionesExt();
  }


  tiposCapital() {
    // return this.http.get(`${ this.url}/politicas`);
    return this.ssps.tiposCapitalExt();
  }

  indicativos() {
    // return this.http.get(`${ this.url}/politicas`);
    return this.ssps.indicativosExt();
  }

  //TODO.valida si la persona esta reportada natural
  reportados(primerNombre: string, segundoNombre: string, primerApellido: string, segundoApellido: string, documento: string, tipo: string) {
    return this.ssps.reportadosEx(primerNombre, segundoNombre, primerApellido, segundoApellido, documento, tipo);
  }

  ReportPersonPJ(nit: string, codigo: string, razon: string) {
    return this.ssps.ExtReportPJ(nit, codigo, razon);
  }

  historial(tipoDocumento: string, numeroDocumento: string, nitEmpresa: string, tipoPersona: string) {
    return this.ssps.historialExt(tipoDocumento, numeroDocumento, nitEmpresa, tipoPersona);
    //return this.http.get(`${this.url}/historial?cedula=${numeroDocumento}`);
  }

  precios() {
    //return this.http.get(`${ this.url}/precios`);
    return this.ssps.preciosEx();
  }

  usos() {
    return this.ssps.usosEx();
  }

  preciosReposicion() {
    //return this.http.get(`${ this.url}/precios`);
    return this.ssps.preciosReposicionEx();
  }

  crearOrdenPedidoPN(body: any) {
    return this.ssps.crearOrdenPedidoPN(body);
  }

  consultarUltimaOrdenPedido(identificacion: any) {
    return this.ssps.consultarUltimaOrdenPedido(identificacion);
  }

  crearOrdenPedidoPJ(body: any) {
    return this.ssps.crearOrdenPedidoPJ(body);
  }

  actualizarOrdenPedidoPN(ordenPedidoId: any, body: any) {
    return this.ssps.actualizarOrdenPedidoPN(ordenPedidoId, body);
  }

  actualizarOrdenPedidoPJ(ordenPedidoId: any, body: any) {
    return this.ssps.actualizarOrdenPedidoPJ(ordenPedidoId, body);
  }

  generarRequestJuridico(body: any) {
    return this.ssps.generarRequestJuridico(body);
  }

  generarRequestLlave(body: any) {
    return this.ssps.generarRequestLlave(body);
  }

  solicitarOTP(body: any) {
    return this.ssps.solicitarOTP(body);
  }


  consultarOrdenPedido(ordenPedidoId: any) {
    return this.ssps.consultarOrdenPedido(ordenPedidoId);
  }

  consultarUrlPSE(body: any) {
    return this.ssps.consultarUrlPSE(body);
  }

  consultarEstadoPagoPSE(body: any) {
    return this.ssps.consultarEstadoPagoPSE(body);
  }

  descargarOrdenPago(solicitudId: any) {
    return this.ssps.descargarOrdenPago(solicitudId);
  }

  cargarComprobantePago(solicitudId: any, body: any) {
    return this.ssps.cargarComprobantePago(solicitudId, body);
  }

  aplicarCodigoDescuento(body: any) {
    return this.ssps.aplicarCodigoDescuento(body);
  }

  validarCodigoDescuento(body: any) {
    return this.ssps.validarCodigoDescuento(body);
  }

  eliminarCodigoDescuento(ordenPedidoId: any, cupon: any) {
    return this.ssps.eliminarCodigoDescuento(ordenPedidoId, cupon);
  }

  departamentos() {
    return this.ssps.departamentosEx();
  }

  //TODO:guarda terminos y condiciones
  AseptN(primerNombre: string, segundoNombre: string, primerApellido: string, segundoApellido: string, tipo: string, numero: string, terminost: boolean, terminosp: boolean, terminostpro: boolean) {
    return this.ssps.ExtAsepN(primerNombre, segundoNombre, primerApellido, segundoApellido, tipo, numero, terminost, terminosp, terminostpro)
  }

  //TODO:guarda terminos y condiciones
  AseptPJ(numero: string, codigo: string, razon: string, terminosp: boolean, terminost: boolean, terminostpro: boolean) {
    return this.ssps.ExtAsepJ(numero, codigo, razon, terminosp, terminost, terminostpro)
  }

  formularioContacto(request: any) {
    return this.ssps.formularioContacto(request)
  }

  obtenerCertificado(request: any) {
    return this.ssps.obtenerCertificado(request)
  }

  unirLlaves(request: any) {
    return this.ssps.unirLlaves(request)
  }

  obtenerIp() {
    return this.ssps.obtenerIp()
  }

  validatedCapcha(token: string) {
    return this.ssps.extCapcha(token)
  }

  echoTest(url: string) {
    this.ssps.echoTest(url)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta echoTest", res)
        },
        error: (error) => {
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.echoTest(url)
          }
        }
      })
  }

  //TODO:guarda terminos y condiciones
  preguntasValidacion(nombres: string, apellidos: string, tipo: string, numero: string, fechaExpedicion: string,
    municipio: string, departamento: string, ip: string, solicitudTitularId: number) {
    return this.ssps.preguntasValidacion(nombres, apellidos, tipo, numero, fechaExpedicion,
      municipio, departamento, ip, solicitudTitularId)
  }

  consultarIntentosValidacion(data: any) {
    return this.ssps.consultarIntentosValidacion(data)
  }

  guardarIntento(data: any) {
    return this.ssps.guardarIntento(data)
  }

  consultarEstadoEBiometrics(data: any) {
    return this.ssps.consultarEstadoEBiometrics(data)
  }

  consultarDatosSolicitanteRevocacion(data: any) {
    return this.ssps.consultarDatosSolicitanteRevocacion(data)
  }

  consultarMotivosRechazo() {
    return this.ssps.consultarMotivosRechazo()
  }

  consultarFactura(request: any) {
    return this.ssps.consultarFactura(request)
  }

  consultarSolicitudesEstadoTramite(request: any) {
    return this.ssps.consultarSolicitudesEstadoTramite(request)
  }

  consultarCertificadosRevocacion(identificacion: any) {
    return this.ssps.consultarCertificadosRevocacion(identificacion)
  }

  detalleCertificadoRevocacion(username: any) {
    return this.ssps.detalleCertificadoRevocacion(username)
  }

  revocarCertificado(body: any) {
    return this.ssps.revocarCertificado(body)
  }

  respuestasValidacion(body: any) {
    return this.ssps.respuestasValidacion(body)
  }

  //TODO:llamado de data 
  LocalStorage() {
    if (!this.getLocalStorage('tipocliente')) return;
    this.typePerson = this.getLocalStorage('tipocliente')!;
    if (this.typePerson == "") return;

    if (!sessionStorage.getItem('usuario')) return;
    this.person = JSON.parse(this.getLocalStorage('usuario')!);
    if (JSON.stringify(this.person) == '{}') return;

    if (!this.getLocalStorage('identificacion')) return;
    this.numero = this.getLocalStorage('identificacion')!;
    if (this.numero == "") return;
  }

  saveBuyData(dataBuy: any, reposicion: boolean) {
    this.logger.log("saveBuyData-person", this.person)
    this.logger.log("saveBuyData-dataBuy", dataBuy)
    let solicitante = dataBuy.solicitantes[0]
    let vigencia = reposicion ? dataBuy.diasReposicion :
      solicitante.detallesCertificado.vigencia === "" || +solicitante.detallesCertificado.vigencia < 365 ? 365 :
        +solicitante.detallesCertificado.vigencia
    let aspectosContables = dataBuy.aspectosContables ?? {}
    let telefono = dataBuy.solicitantes[0].telefonos[0] ?? {}
    const data: any = {
      anos: reposicion ? vigencia : Math.floor(vigencia / 365),
      esVigenciaDias: reposicion,
      primerNombre: solicitante.primerNombre.toUpperCase().trim(),
      segundoNombre: solicitante.segundoNombre !== null &&
        solicitante.segundoNombre !== undefined &&
        solicitante.segundoNombre !== "" ? solicitante.segundoNombre.toUpperCase().trim() : "",
      primerApellido: solicitante.primerApellido.toUpperCase().trim(),
      segundoApellido: solicitante.segundoApellido !== null &&
        solicitante.segundoApellido !== undefined &&
        solicitante.segundoApellido !== "" ? solicitante.segundoApellido.toUpperCase().trim() : "",
      cantidad: solicitante.detallesCertificado.cantidad ?? null,
      cliente: this.typePerson,
      costo: 0,
      costoBase: 0,
      ica: 0,
      iva: 0,
      reteIva: 0,
      dispo: solicitante.detallesCertificado.mecanismoEntrega.replace("FISICO", "FÍSICO").replace("Fisico", "Físico").replace("fisico", "físico").replace("REUSO", "reúso").replace("Reuso", "Reúso").replace("reuso", "reúso"),
      idispo: "",
      idpoli: solicitante.detallesCertificado.idPolitica,
      numero: this.person.numero,
      politica: solicitante.detallesCertificado.nombrePolitica.replace("Juridica", "Jurídica").replace("Funcion", "Función"),
      terminosp: this.person.terminosp,
      terminost: this.person.terminost,
      terminostpro: this.person.terminostpro,
      tipo: this.person.tipo,
      uid: this.person.uid,
      correo: dataBuy.solicitantes[0].correoElectronico.toUpperCase(),
      correo1: "",
      profesion: dataBuy.solicitantes[0].profesion ?? '',
      tarjetaProfesional: dataBuy.solicitantes[0].tarjetaProfesional ?? '',
      institucionGrado: dataBuy.solicitantes[0].institucionGrado ?? '',
      fechaExpTarjetaProfesional: dataBuy.solicitantes[0].fechaExpTarjetaProfesional ?? '',
      celular: dataBuy.solicitantes[0].celular,
      indicativo: telefono.indicativo ?? "",
      extension: telefono.extension ?? "",
      telefono: telefono.telefono ?? "",
      tipoCapital: aspectosContables.tipoCapital ?? '',
      regimenTributario: aspectosContables.regimenTributario ?? '',
      actividadEconomica: aspectosContables.ciuu ?? '',
      departamento: +dataBuy.solicitantes[0].departamento,
      municipio: +dataBuy.solicitantes[0].ciudad
    }

    let datosBasicoDireccion = dataBuy.solicitantes[0].direccion

    let barrio = datosBasicoDireccion.includes("ADICIONAL") ?
      datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("BARRIO") + 7,
        datosBasicoDireccion.indexOf("ADICIONAL") - 1) :
      datosBasicoDireccion.includes("BARRIO") ?
        datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("BARRIO") + 7) : ""

    let adicional = datosBasicoDireccion.includes("ADICIONAL") ?
      datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("ADICIONAL") + 10) : ""

    let tipoDireccion = "Otro"
    let tipoNumero = ""
    let numero = ""
    let numero2 = ""
    if (datosBasicoDireccion.includes("#") &&
      datosBasicoDireccion.includes("BARRIO")) {
      let tipoDireccionIni = datosBasicoDireccion.substring(0, datosBasicoDireccion.indexOf("#")).split(' ').filter((i: any) => i)
      if (tipoDireccionIni.length > 2) {
        tipoDireccion = tipoDireccionIni[0] + " " + tipoDireccionIni[1]
        tipoNumero = tipoDireccionIni[2]
      }
      else {
        tipoDireccion = tipoDireccionIni[0]
        tipoNumero = tipoDireccionIni[1]
      }
      if (tipoDireccion.length < 3) {
        tipoDireccion = "Otro"
      }
      let numeroIni = datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("#") + 1, datosBasicoDireccion.indexOf("BARRIO")).trim().split('-').filter((i: any) => i)
      numero = numeroIni[0]
      numero2 = numeroIni[1]
    }

    let objetoDireccion = {
      tipo: tipoDireccion.toLocaleLowerCase().replace(/\b\w/g, first => first.toLocaleUpperCase()),
      tipoNumero: tipoNumero,
      numero: numero,
      numero2: numero2,
      barrio: barrio,
      adicional: adicional,
      tipoOtroNumero: datosBasicoDireccion.includes("BARRIO") ?
        datosBasicoDireccion.substring(0, datosBasicoDireccion.indexOf("BARRIO")) :
        datosBasicoDireccion
    }

    data.objetoDireccion = objetoDireccion
    data.direccion = objetoDireccion.tipoOtroNumero
    if (solicitante.detallesCertificado.descripcionUso !== undefined && solicitante.detallesCertificado.descripcionUso !== null
      && solicitante.detallesCertificado.descripcionUso !== "") {
      data.uso = {
        id: solicitante.detallesCertificado.usoId,
        descripcion: solicitante.detallesCertificado.descripcionUso,
        otro: solicitante.detallesCertificado.usoId === "12" ? solicitante.detallesCertificado.descripcionUso : ""
      }
    }

    this.person = data;
    this.logger.log("saveBuyData-save", data)
    this.saveLocalStorage('usuario', JSON.stringify(data));
  }

  saveBuyDataPJ(dataBuy: any, reposicion: boolean) {
    this.logger.log("saveBuyDataPJ-person", this.person)
    this.logger.log("saveBuyDataPJ-dataBuy", dataBuy)
    let solicitante = dataBuy.solicitantes[0]
    let empresa = dataBuy.empresa
    let vigencia = reposicion ? dataBuy.diasReposicion :
      solicitante.detallesCertificado.vigencia === "" || +solicitante.detallesCertificado.vigencia < 365 ? 365 :
        +solicitante.detallesCertificado.vigencia
    let telefono = solicitante.telefonos[0] ?? {}
    let aspectosContables = dataBuy.aspectosContables ?? {}
    const data: any = {
      anos: reposicion ? vigencia : Math.floor(vigencia / 365),
      esVigenciaDias: reposicion,
      cantidad: solicitante.detallesCertificado.cantidad ?? null,
      cliente: this.typePerson,
      costo: 0,
      costoBase: 0,
      ica: 0,
      iva: 0,
      reteIva: 0,
      dispo: solicitante.detallesCertificado.mecanismoEntrega.replace("FISICO", "FÍSICO").replace("Fisico", "Físico").replace("fisico", "físico").replace("REUSO", "reúso").replace("Reuso", "Reúso").replace("reuso", "reúso"),
      idispo: "",
      idpoli: solicitante.detallesCertificado.idPolitica,
      politica: solicitante.detallesCertificado.nombrePolitica.replace("Juridica", "Jurídica").replace("Funcion", "Función"),
      correo: empresa.correoElectronico.toUpperCase(),
      correo1: "",
      municipio: +empresa.municipio,
      departamento: +empresa.departamento,
      celular: empresa.celular,
      indicativo: telefono.indicativo ?? "",
      extension: telefono.extension ?? "",
      telefono: telefono.telefono ?? "",
      tipoCapital: aspectosContables.tipoCapital ?? '',
      regimenTributario: aspectosContables.regimenTributario ?? '',
      actividadEconomica: aspectosContables.ciuu ?? '',
      razon: empresa.razonSocial.toUpperCase().trim()
    }

    let datosBasicoDireccion = empresa.direccion

    let barrio = datosBasicoDireccion.includes("ADICIONAL") ?
      datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("BARRIO") + 7,
        datosBasicoDireccion.indexOf("ADICIONAL") - 1) :
      datosBasicoDireccion.includes("BARRIO") ?
        datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("BARRIO") + 7) : ""

    let adicional = datosBasicoDireccion.includes("ADICIONAL") ?
      datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("ADICIONAL") + 10) : ""

    let tipoDireccion = "Otro"
    let tipoNumero = ""
    let numero = ""
    let numero2 = ""
    if (datosBasicoDireccion.includes("#") &&
      datosBasicoDireccion.includes("BARRIO")) {
      let tipoDireccionIni = datosBasicoDireccion.substring(0, datosBasicoDireccion.indexOf("#")).split(' ').filter((i: any) => i)
      if (tipoDireccionIni.length > 2) {
        tipoDireccion = tipoDireccionIni[0] + " " + tipoDireccionIni[1]
        tipoNumero = tipoDireccionIni[2]
      }
      else {
        tipoDireccion = tipoDireccionIni[0]
        tipoNumero = tipoDireccionIni[1]
      }
      if (tipoDireccion.length < 3) {
        tipoDireccion = "Otro"
      }
      let numeroIni = datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("#") + 1, datosBasicoDireccion.indexOf("BARRIO")).trim().split('-').filter((i: any) => i)
      numero = numeroIni[0]
      numero2 = numeroIni[1]
    }

    let objetoDireccion = {
      tipo: tipoDireccion.toLocaleLowerCase().replace(/\b\w/g, first => first.toLocaleUpperCase()),
      tipoNumero: tipoNumero,
      numero: numero,
      numero2: numero2,
      barrio: barrio,
      adicional: adicional,
      tipoOtroNumero: datosBasicoDireccion.includes("BARRIO") ?
        datosBasicoDireccion.substring(0, datosBasicoDireccion.indexOf("BARRIO")) :
        datosBasicoDireccion
    }

    data.objetoDireccion = objetoDireccion
    data.direccion = objetoDireccion.tipoOtroNumero

    if (solicitante.detallesCertificado.descripcionUso !== undefined && solicitante.detallesCertificado.descripcionUso !== null
      && solicitante.detallesCertificado.descripcionUso !== "") {
      data.uso = {
        id: solicitante.detallesCertificado.usoId,
        descripcion: solicitante.detallesCertificado.descripcionUso,
        otro: solicitante.detallesCertificado.usoId === "12" ? solicitante.detallesCertificado.descripcionUso : ""
      }
    }

    Object.assign(this.person, data)
    this.logger.log("saveBuyData-save", this.person)
    this.saveLocalStorage('usuario', JSON.stringify(this.person));
  }

  saveSolicitanteDataPJ(dataBuy: any) {
    this.logger.log("saveSolicitanteDataPJ-dataBuy", dataBuy)
    let solicitanteLocal = JSON.parse(this.getLocalStorage('solicitante')!);
    let solicitante = dataBuy.solicitantes[0]
    let telefono = solicitante.telefonos[0] ?? {}
    const data = {
      correo: solicitante.correoElectronico.toUpperCase(),
      correo1: "",
      profesion: solicitante.profesion ?? '',
      tarjetaProfesional: solicitante.tarjetaProfesional ?? '',
      institucionGrado: solicitante.institucionGrado ?? '',
      fechaExpTarjetaProfesional: solicitante.fechaExpTarjetaProfesional ?? '',
      cargoEmpresa: solicitante.cargo ?? '',
      fechaNombramiento: solicitante.fechaNombramiento ?? '',
      actaPosesion: solicitante.actaPosesion ?? '',
      municipio: solicitante.ciudad,
      departamento: solicitante.departamento,
      celular: solicitante.celular,
      direccion: solicitante.direccion,
      indicativo: telefono.indicativo ?? "",
      extension: telefono.extension ?? "",
      telefono: telefono.telefono ?? "",
      primerNombre: solicitante.primerNombre.toUpperCase().trim(),
      segundoNombre: solicitante.segundoNombre !== null &&
        solicitante.segundoNombre !== undefined &&
        solicitante.segundoNombre !== "" ? solicitante.segundoNombre.toUpperCase().trim() : "",
      primerApellido: solicitante.primerApellido.toUpperCase().trim(),
      segundoApellido: solicitante.segundoApellido !== null &&
        solicitante.segundoApellido !== undefined &&
        solicitante.segundoApellido !== "" ? solicitante.segundoApellido.toUpperCase().trim() : "",
      cantidad: solicitante.detallesCertificado.cantidad ?? null,
    }
    Object.assign(solicitanteLocal, data)
    this.logger.log("saveSolicitanteDataPJ-save", solicitanteLocal)
    this.saveLocalStorage('solicitante', JSON.stringify(solicitanteLocal));
  }

  saveEntregaData(dataBuy: any) {
    this.logger.log("saveEntregaData-dataBuy", dataBuy)
    let infoEnvio = dataBuy.solicitantes[0].informacionEnvio ?? {}
    let telefono = infoEnvio.telefonoEnvio[0] ?? {}
    let municipioData = infoEnvio.municipioEnvio ?? "";
    let codDepartamento = municipioData !== "" && municipioData.length > 1 ? +municipioData.substring(0, 2) : '';
    let codMunicipio = municipioData !== "" && municipioData.length > 2 ? +municipioData.substring(2) : '';
    const data: any = {
      departamento: codDepartamento,
      municipio: codMunicipio,
      indicativo: telefono.indicativo ?? "",
      extension: telefono.extension ?? "",
      telefono: telefono.telefono ?? "",
      celular: infoEnvio.celularEnvio ?? "",
      correo: dataBuy.solicitantes[0].correoElectronico.toUpperCase(),
      tipo: (infoEnvio.preferenciaEnvio ?? "").includes("OFCCER") ? "OFICINA" : "ENVIO"
    }

    let datosBasicoDireccion = infoEnvio.direccionEnvio ?? ""

    let barrio = datosBasicoDireccion.includes("ADICIONAL") ?
      datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("BARRIO") + 7,
        datosBasicoDireccion.indexOf("ADICIONAL") - 1) :
      datosBasicoDireccion.includes("BARRIO") ?
        datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("BARRIO") + 7) : ""

    let adicional = datosBasicoDireccion.includes("ADICIONAL") ?
      datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("ADICIONAL") + 10) : ""

    let tipoDireccion = "Otro"
    let tipoNumero = ""
    let numero = ""
    let numero2 = ""
    if (datosBasicoDireccion.includes("#") &&
      datosBasicoDireccion.includes("BARRIO")) {
      let tipoDireccionIni = datosBasicoDireccion.substring(0, datosBasicoDireccion.indexOf("#")).split(' ').filter((i: any) => i)
      if (tipoDireccionIni.length > 2) {
        tipoDireccion = tipoDireccionIni[0] + " " + tipoDireccionIni[1]
        tipoNumero = tipoDireccionIni[2]
      }
      else {
        tipoDireccion = tipoDireccionIni[0]
        tipoNumero = tipoDireccionIni[1]
      }
      if (tipoDireccion.length < 3) {
        tipoDireccion = "Otro"
      }
      let numeroIni = datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("#") + 1, datosBasicoDireccion.indexOf("BARRIO")).trim().split('-').filter((i: any) => i)
      numero = numeroIni[0]
      numero2 = numeroIni[1]
    }

    let objetoDireccion = {
      tipo: tipoDireccion.toLocaleLowerCase().replace(/\b\w/g, first => first.toLocaleUpperCase()),
      tipoNumero: tipoNumero,
      numero: numero,
      numero2: numero2,
      barrio: barrio,
      adicional: adicional,
      tipoOtroNumero: datosBasicoDireccion.includes("BARRIO") ?
        datosBasicoDireccion.substring(0, datosBasicoDireccion.indexOf("BARRIO")) :
        datosBasicoDireccion
    }

    data.objetoDireccion = objetoDireccion
    data.direccion = objetoDireccion.tipoOtroNumero
    data.direccionCompleta = infoEnvio.direccionEnvio ?? ""

    this.logger.log("saveEntregaData-save", data)
    this.saveLocalStorage('entrega', JSON.stringify(data));
  }

  saveFacturacionData(dataBuy: any) {
    this.logger.log("saveFacturacionData-dataBuy", dataBuy)
    let aspectosContables = dataBuy.aspectosContables ?? {}
    let tipo = this.person.tipo
    let numeroIdentificacion = this.person.numero
    let responsablePago = aspectosContables.responsablePago ?? {}
    let telefono = responsablePago.telefonos ? responsablePago.telefonos[0] ?? {} : {}
    let nombres = responsablePago.nombresApellidos.split(" ");
    const data: any = {
      tipo: responsablePago.tipoIdentificacion ?? tipo,
      numero: responsablePago.numeroIdentificacion ?? numeroIdentificacion,
      nombres: nombres.length > 2 ? nombres[0] + " " + nombres[1] : nombres[0],
      apellidos: nombres.length > 3 ? nombres[2] + " " + nombres[3] :
        nombres.length > 2 ? nombres[2] : nombres.length > 1 ? nombres[1] : "",
      razon: responsablePago.nombresApellidos,
      direccion: responsablePago.direccion ?? '',
      indicativo: telefono.indicativo ?? "",
      telefono: telefono.telefono ?? '',
      extension: telefono.extension ?? '',
      celular: responsablePago.celular ?? '',
      correo: (responsablePago.correoElectronico ?? '').toUpperCase(),
      municipio: responsablePago.municipio,
      departamento: responsablePago.departamento,
      regimenTributario: responsablePago.regimenTributario ?? '',
      tipoCapital: responsablePago.tipoCapital ?? ''
    }

    let datosBasicoDireccion = responsablePago.direccion ?? ''

    let barrio = datosBasicoDireccion.includes("ADICIONAL") ?
      datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("BARRIO") + 7,
        datosBasicoDireccion.indexOf("ADICIONAL") - 1) :
      datosBasicoDireccion.includes("BARRIO") ?
        datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("BARRIO") + 7) : ""

    let adicional = datosBasicoDireccion.includes("ADICIONAL") ?
      datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("ADICIONAL") + 10) : ""

    let tipoDireccion = "Otro"
    let tipoNumero = ""
    let numero = ""
    let numero2 = ""
    if (datosBasicoDireccion.includes("#") &&
      datosBasicoDireccion.includes("BARRIO")) {
      let tipoDireccionIni = datosBasicoDireccion.substring(0, datosBasicoDireccion.indexOf("#")).split(' ').filter((i: any) => i)
      if (tipoDireccionIni.length > 2) {
        tipoDireccion = tipoDireccionIni[0] + " " + tipoDireccionIni[1]
        tipoNumero = tipoDireccionIni[2]
      }
      else {
        tipoDireccion = tipoDireccionIni[0]
        tipoNumero = tipoDireccionIni[1]
      }
      if (tipoDireccion.length < 3) {
        tipoDireccion = "Otro"
      }
      let numeroIni = datosBasicoDireccion.substring(datosBasicoDireccion.indexOf("#") + 1, datosBasicoDireccion.indexOf("BARRIO")).trim().split('-').filter((i: any) => i)
      numero = numeroIni[0]
      numero2 = numeroIni[1]
    }

    let objetoDireccion = {
      tipo: tipoDireccion.toLocaleLowerCase().replace(/\b\w/g, first => first.toLocaleUpperCase()),
      tipoNumero: tipoNumero,
      numero: numero,
      numero2: numero2,
      barrio: barrio,
      adicional: adicional,
      tipoOtroNumero: datosBasicoDireccion.includes("BARRIO") ?
        datosBasicoDireccion.substring(0, datosBasicoDireccion.indexOf("BARRIO")) :
        datosBasicoDireccion
    }

    data.objetoDireccion = objetoDireccion
    data.direccion = objetoDireccion.tipoOtroNumero

    this.logger.log("saveFacturacionData-save", data)
    this.saveLocalStorage('facturacion', JSON.stringify(data));
  }

  saveOrdenPedidoData(datosOrden: any) {
    this.logger.log("datosOrden", datosOrden)
    //TODO Validar el tipoPersona, para cuando se implemente el flujo de Juridico
    var telefono = datosOrden.datosBasicos.telefono && datosOrden.datosBasicos.telefono.length > 0 ?
      datosOrden.datosBasicos.telefono[0] : {
        extension: "",
        indicativo: "",
        telefono: ""
      }
    const data: any = {
      anos: datosOrden.detallesCertificado.vigencia,
      esReposicion: datosOrden.detallesCertificado.reposicion,
      esVigenciaDias: datosOrden.detallesCertificado.reposicion,
      primerNombre: datosOrden.datosBasicos.primerNombre,
      segundoNombre: datosOrden.datosBasicos.segundoNombre,
      primerApellido: datosOrden.datosBasicos.primerApellido,
      segundoApellido: datosOrden.datosBasicos.segundoApellido,
      cantidad: datosOrden.solicitantes.length,
      cliente: datosOrden.tipoPersona,
      costo: datosOrden.detallesCertificado.valorNeto,
      descuentoCupon: datosOrden.detallesCertificado.valorDescuentoCupon,
      costoBase: datosOrden.detallesCertificado.valorBaseLiquidacion,
      existeCSR: datosOrden.detallesCertificado.existeCSR,
      ica: datosOrden.detallesCertificado.porcentajeIca,
      iva: datosOrden.detallesCertificado.porcentajeIva,
      reteiva: datosOrden.detallesCertificado.porcentajeReteIva,
      estampillas: datosOrden.detallesCertificado.estampillas,
      dispo: datosOrden.detallesCertificado.mecanismoEntrega.replace("FISICO", "FÍSICO").replace("Fisico", "Físico").replace("fisico", "físico").replace("REUSO", "reúso").replace("Reuso", "Reúso").replace("reuso", "reúso"),
      idispo: "",
      idpoli: 0,
      numero: datosOrden.datosBasicos.numeroIdentificacion,
      politica: datosOrden.detallesCertificado.politica.replace("Juridica", "Jurídica").replace("Funcion", "Función"),
      terminosp: datosOrden.aceptoTerminosSn,
      terminost: datosOrden.aceptoTerminosSn,
      terminostpro: datosOrden.aceptoTerminosSn,
      tipo: datosOrden.datosBasicos.tipoIdentificacion,
      uid: uuidv4(),
      correo: datosOrden.datosBasicos.correoElectronico.toUpperCase(),
      correo1: datosOrden.datosBasicos.correoElectronico.toUpperCase(),
      profesion: "",
      tarjetaProfesional: "",
      institucionGrado: "",
      fechaExpTarjetaProfesional: "",
      idTitular: datosOrden.solicitantes[0].solicitudTitularId,
      identidadValidada: datosOrden.solicitantes[0].identidadValidada,
      ip: this.ip,
      fechaCreacion: datosOrden.datosBasicos.fechaCreacion,
      documentosCargados: [],
      proceso: datosOrden.datosBasicos.fechaPago !== "" && datosOrden.datosBasicos.realizoPago === "S" ?
        1 : 0,
      celular: datosOrden.datosBasicos.celular,
      telefono: telefono.telefono,
      extension: telefono.extension,
      indicativo: telefono.indicativo,
      municipio: +datosOrden.datosBasicos.municipio,
      departamento: +datosOrden.datosBasicos.departamento,
      tipoCapital: '',
      actividadEconomica: '',
      faltantes: datosOrden.faltantes,
      solicitudFinalizada: datosOrden.solicitudFinalizada,
      regimenTributario: datosOrden.datosBasicos.regimenTributario ?? '',
      usoCertificado: datosOrden.detallesCertificado.usoCertificado ?? -1,
      otroUso: datosOrden.detallesCertificado.otroUso ?? '',
      uso: datosOrden.detallesCertificado.uso ?? ''
    }

    if (datosOrden.documentos) {
      datosOrden.documentos.forEach((element: any, index: any) => {
        data.documentosCargados.push({
          id: element.tipo,
          fondo: '#33f0b7',
          colores: '#2d3d3d',
          title: 'Documento cargado con exito',
          subtitle: element.nombre
        })
        return ""
      });
    }

    let barrio = datosOrden.datosBasicos.direccion.includes("ADICIONAL") ?
      datosOrden.datosBasicos.direccion.substring(datosOrden.datosBasicos.direccion.indexOf("BARRIO") + 7,
        datosOrden.datosBasicos.direccion.indexOf("ADICIONAL") - 1) :
      datosOrden.datosBasicos.direccion.includes("BARRIO") ?
        datosOrden.datosBasicos.direccion.substring(datosOrden.datosBasicos.direccion.indexOf("BARRIO") + 7) : ""

    let adicional = datosOrden.datosBasicos.direccion.includes("ADICIONAL") ?
      datosOrden.datosBasicos.direccion.substring(datosOrden.datosBasicos.direccion.indexOf("ADICIONAL") + 10) : ""

    let tipoDireccion = "Otro"
    let tipoNumero = ""
    let numero = ""
    let numero2 = ""
    if (datosOrden.datosBasicos.direccion.includes("#") &&
      datosOrden.datosBasicos.direccion.includes("BARRIO")) {
      let tipoDireccionIni = datosOrden.datosBasicos.direccion.substring(0, datosOrden.datosBasicos.direccion.indexOf("#")).split(' ').filter((i: any) => i)
      if (tipoDireccionIni.length > 2) {
        tipoDireccion = tipoDireccionIni[0] + " " + tipoDireccionIni[1]
        tipoNumero = tipoDireccionIni[2]
      }
      else {
        tipoDireccion = tipoDireccionIni[0]
        tipoNumero = tipoDireccionIni[1]
      }

      if (tipoDireccion.length < 3) {
        tipoDireccion = "Otro"
      }

      let numeroIni = datosOrden.datosBasicos.direccion.substring(datosOrden.datosBasicos.direccion.indexOf("#") + 1, datosOrden.datosBasicos.direccion.indexOf("BARRIO")).trim().split('-').filter((i: any) => i)
      numero = numeroIni[0]
      numero2 = numeroIni[1]
    }

    let objetoDireccion = {
      tipo: tipoDireccion.toLocaleLowerCase().replace(/\b\w/g, first => first.toLocaleUpperCase()),
      tipoNumero: tipoNumero,
      numero: numero,
      numero2: numero2,
      barrio: barrio,
      adicional: adicional,
      tipoOtroNumero: datosOrden.datosBasicos.direccion.includes("BARRIO") ?
        datosOrden.datosBasicos.direccion.substring(0, datosOrden.datosBasicos.direccion.indexOf("BARRIO")) :
        datosOrden.datosBasicos.direccion
    }

    data.objetoDireccion = objetoDireccion
    data.direccion = objetoDireccion.tipoOtroNumero

    if (datosOrden.profesionalTitulado) {
      data.profesion = datosOrden.profesionalTitulado.profesion
      data.fechaExpTarjetaProfesional = datosOrden.profesionalTitulado.fechaExpedicion
      data.institucionGrado = datosOrden.profesionalTitulado.institucionGrado
      data.tarjetaProfesional = datosOrden.profesionalTitulado.numTarjetaProfesional
    }
    this.person = data;
    this.logger.log("saveOrdenPedidoData", data)
    this.saveLocalStorage("identificacion", datosOrden.datosBasicos.numeroIdentificacion);
    this.saveLocalStorage("tipocliente", datosOrden.tipoPersona);
    this.saveLocalStorage('usuario', JSON.stringify(data));
    this.saveOrdenPedidoFacturacionData(datosOrden)
    this.SavePagoOrdenPedidoData(datosOrden.detallesCertificado, datosOrden.datosBasicos)
    this.SaveEntregaOrdenPedidoData(datosOrden.informacionEnvio)
    if (datosOrden.datosContactoTecnico) {
      this.SaveContactoOrdenPedidoData(datosOrden.datosContactoTecnico)
    }
  }

  saveOrdenPedidoDataPJ(datosOrden: any) {
    this.logger.log("datosOrdenPJ", datosOrden)
    //TODO Validar el tipoPersona, para cuando se implemente el flujo de Juridico
    var telefono = datosOrden.datosBasicos.telefono && datosOrden.datosBasicos.telefono.length > 0 ?
      datosOrden.datosBasicos.telefono[0] : {
        extension: "",
        indicativo: "",
        telefono: ""
      }
    const data: any = {
      anos: datosOrden.detallesCertificado.vigencia,
      esReposicion: datosOrden.detallesCertificado.reposicion,
      esVigenciaDias: datosOrden.detallesCertificado.reposicion,
      existeCSR: datosOrden.detallesCertificado.existeCSR,
      cantidad: datosOrden.solicitantes.length,
      cliente: datosOrden.tipoPersona,
      costo: datosOrden.detallesCertificado.valorNeto,
      descuentoCupon: datosOrden.detallesCertificado.valorDescuentoCupon,
      costoBase: datosOrden.detallesCertificado.valorBaseLiquidacion,
      ica: datosOrden.detallesCertificado.porcentajeIca,
      iva: datosOrden.detallesCertificado.porcentajeIva,
      reteiva: datosOrden.detallesCertificado.porcentajeReteIva,
      estampillas: datosOrden.detallesCertificado.estampillas,
      dispo: datosOrden.detallesCertificado.mecanismoEntrega.replace("FISICO", "FÍSICO").replace("Fisico", "Físico").replace("fisico", "físico").replace("REUSO", "reúso").replace("Reuso", "Reúso").replace("reuso", "reúso"),
      idispo: "",
      idpoli: 0,
      razon: datosOrden.datosBasicos.razonSocial,
      numero: datosOrden.datosBasicos.numeroIdentificacion,
      politica: datosOrden.detallesCertificado.politica.replace("Juridica", "Jurídica").replace("Funcion", "Función"),
      terminosp: datosOrden.aceptoTerminosSn,
      terminost: datosOrden.aceptoTerminosSn,
      terminostpro: datosOrden.aceptoTerminosSn,
      tipo: datosOrden.datosBasicos.tipoIdentificacion,
      uid: uuidv4(),
      correo: datosOrden.datosBasicos.correoElectronico.toUpperCase(),
      correo1: datosOrden.datosBasicos.correoElectronico.toUpperCase(),
      idTitular: datosOrden.solicitantes[0].solicitudTitularId,
      identidadValidada: datosOrden.solicitantes[0].identidadValidada,
      ip: this.ip,
      fechaCreacion: datosOrden.datosBasicos.fechaCreacion,
      documentosCargados: [],
      proceso: datosOrden.datosBasicos.fechaPago !== "" && datosOrden.datosBasicos.realizoPago === "S" ?
        1 : 0,
      celular: datosOrden.datosBasicos.celular,
      telefono: telefono.telefono,
      extension: telefono.extension,
      indicativo: telefono.indicativo,
      municipio: +datosOrden.datosBasicos.municipio,
      departamento: +datosOrden.datosBasicos.departamento,
      tipoCapital: datosOrden.datosBasicos.tipoCapital,
      regimenTributario: datosOrden.datosBasicos.regimenTributario,
      actividadEconomica: datosOrden.datosBasicos.actividadEconomica,
      faltantes: datosOrden.faltantes,
      solicitudFinalizada: datosOrden.solicitudFinalizada,
      usoCertificado: datosOrden.detallesCertificado.usoCertificado ?? -1,
      otroUso: datosOrden.detallesCertificado.otroUso ?? '',
      uso: datosOrden.detallesCertificado.uso ?? ''
    }

    let barrio = datosOrden.datosBasicos.direccion.includes("ADICIONAL") ?
      datosOrden.datosBasicos.direccion.substring(datosOrden.datosBasicos.direccion.indexOf("BARRIO") + 7,
        datosOrden.datosBasicos.direccion.indexOf("ADICIONAL") - 1) :
      datosOrden.datosBasicos.direccion.includes("BARRIO") ?
        datosOrden.datosBasicos.direccion.substring(datosOrden.datosBasicos.direccion.indexOf("BARRIO") + 7) : ""

    let adicional = datosOrden.datosBasicos.direccion.includes("ADICIONAL") ?
      datosOrden.datosBasicos.direccion.substring(datosOrden.datosBasicos.direccion.indexOf("ADICIONAL") + 10) : ""

    let tipoDireccion = "Otro"
    let tipoNumero = ""
    let numero = ""
    let numero2 = ""
    if (datosOrden.datosBasicos.direccion.includes("#") &&
      datosOrden.datosBasicos.direccion.includes("BARRIO")) {
      let tipoDireccionIni = datosOrden.datosBasicos.direccion.substring(0, datosOrden.datosBasicos.direccion.indexOf("#")).split(' ').filter((i: any) => i)
      if (tipoDireccionIni.length > 2) {
        tipoDireccion = tipoDireccionIni[0] + " " + tipoDireccionIni[1]
        tipoNumero = tipoDireccionIni[2]
      }
      else {
        tipoDireccion = tipoDireccionIni[0]
        tipoNumero = tipoDireccionIni[1]
      }
      if (tipoDireccion.length < 3) {
        tipoDireccion = "Otro"
      }
      let numeroIni = datosOrden.datosBasicos.direccion.substring(datosOrden.datosBasicos.direccion.indexOf("#") + 1, datosOrden.datosBasicos.direccion.indexOf("BARRIO")).trim().split('-').filter((i: any) => i)
      numero = numeroIni[0]
      numero2 = numeroIni[1]
    }

    let objetoDireccion = {
      tipo: tipoDireccion.toLocaleLowerCase().replace(/\b\w/g, first => first.toLocaleUpperCase()),
      tipoNumero: tipoNumero,
      numero: numero,
      numero2: numero2,
      barrio: barrio,
      adicional: adicional,
      tipoOtroNumero: datosOrden.datosBasicos.direccion.includes("BARRIO") ?
        datosOrden.datosBasicos.direccion.substring(0, datosOrden.datosBasicos.direccion.indexOf("BARRIO")) :
        datosOrden.datosBasicos.direccion
    }

    data.objetoDireccion = objetoDireccion
    data.direccion = objetoDireccion.tipoOtroNumero

    if (datosOrden.documentos) {
      datosOrden.documentos.filter((p: { tipo: string; }) => p.tipo === "rut" || p.tipo === "pago").forEach((element: any, index: any) => {
        data.documentosCargados.push({
          id: element.tipo,
          fondo: '#33f0b7',
          colores: '#2d3d3d',
          title: 'Documento cargado con exito',
          subtitle: element.nombre
        })
        return ""
      });
    }
    this.person = data;
    this.logger.log("saveOrdenPedidoData", data)
    this.saveLocalStorage("identificacion", datosOrden.datosBasicos.numeroIdentificacion);
    this.saveLocalStorage("tipocliente", datosOrden.tipoPersona);
    this.saveLocalStorage('usuario', JSON.stringify(data));
    this.saveSolicitanteOrdenPedidoData(datosOrden)
    this.saveOrdenPedidoFacturacionData(datosOrden)
    this.SavePagoOrdenPedidoData(datosOrden.detallesCertificado, datosOrden.datosBasicos)
    this.SaveEntregaOrdenPedidoData(datosOrden.informacionEnvio)
    if (datosOrden.datosContactoTecnico) {
      this.SaveContactoOrdenPedidoData(datosOrden.datosContactoTecnico)
    }
  }

  saveSolicitanteOrdenPedidoData(datosOrden: any) {
    this.logger.log("saveSolicitanteOrdenPedidoData", datosOrden)
    var solicitante = datosOrden.solicitantes[0]
    //TODO Validar el tipoPersona, para cuando se implemente el flujo de Juridico
    var telefono = solicitante.telefono && solicitante.telefono.length > 0 ?
      solicitante.telefono[0] : {
        extension: "",
        indicativo: "",
        telefono: ""
      }


    const data: any = {
      primerNombre: solicitante.primerNombre ?? '',
      segundoNombre: solicitante.segundoNombre ?? '',
      primerApellido: solicitante.primerApellido ?? '',
      segundoApellido: solicitante.segundoApellido ?? '',
      numero: solicitante.numeroIdentificacion,
      tipo: solicitante.tipoIdentificacion,
      correo: solicitante.correoElectronico.toUpperCase(),
      correo1: solicitante.correoElectronico.toUpperCase(),
      profesion: "",
      tarjetaProfesional: "",
      institucionGrado: "",
      fechaExpTarjetaProfesional: "",
      documentosCargados: [],
      celular: solicitante.celular,
      idTitular: solicitante.solicitudTitularId,
      telefono: telefono.telefono,
      extension: telefono.extension,
      indicativo: telefono.indicativo,
      municipio: +solicitante.municipio,
      departamento: +solicitante.departamento,
      cargoEmpresa: solicitante.cargoEmpresa,
      fechaNombramiento: solicitante.fechaNombramiento,
      actaPosesion: solicitante.actaPosesion,
      esRepresentanteLegal: solicitante.esRepresentanteLegal === "S",
      esFuncionarioPublico: solicitante.esFuncionarioPublico === "S",
    }

    if (datosOrden.documentos) {
      datosOrden.documentos.filter((p: { tipo: string; }) => p.tipo !== "rut").forEach((element: any, index: any) => {
        data.documentosCargados.push({
          id: element.tipo,
          fondo: '#33f0b7',
          colores: '#2d3d3d',
          title: 'Documento cargado con exito',
          subtitle: element.nombre
        })
        return ""
      });
    }
    if (datosOrden.profesionalTitulado) {
      data.profesion = datosOrden.profesionalTitulado.profesion
      data.fechaExpTarjetaProfesional = datosOrden.profesionalTitulado.fechaExpedicion
      data.institucionGrado = datosOrden.profesionalTitulado.institucionGrado
      data.tarjetaProfesional = datosOrden.profesionalTitulado.numTarjetaProfesional
    }
    this.logger.log("saveSolicitanteOrdenPedidoData", data)
    this.saveLocalStorage('solicitante', JSON.stringify(data));
  }

  saveOrdenPedidoFacturacionData(datosOrden: any) {
    var facturacionData = datosOrden.datosFacturacion
    var solicitante = datosOrden.solicitantes[0]
    this.logger.log("saveOrdenPedidoFacturacionData", facturacionData)
    if (facturacionData.correoElectronico !== undefined && facturacionData.correoElectronico !== null
      && facturacionData.correoElectronico !== ""
    ) {
      var telefono = facturacionData.telefono && facturacionData.telefono.length > 0 ?
        facturacionData.telefono[0] : {
          extension: "",
          indicativo: "",
          telefono: ""
        }
      const data: any = {
        tipo: facturacionData.tipoIdentificacion,
        numero: facturacionData.numeroIdentificacion,
        nombres: facturacionData.nombres,
        apellidos: facturacionData.apellidos,
        razon: facturacionData.nombres + (facturacionData.apellidos && facturacionData.apellidos !== "" ?
          (" " + facturacionData.apellidos) : ""),
        direccion: facturacionData.direccion,
        indicativo: telefono.indicativo,
        telefono: telefono.telefono,
        extension: telefono.extension,
        celular: facturacionData.celular ?? '',
        correo: (facturacionData.correoElectronico ?? '').toUpperCase(),
        agente: solicitante.codigoAgente ?? '',
        regimenTributario: facturacionData.regimenTributario,
        tipoCapital: facturacionData.tipoCapital,
        departamento: +(facturacionData.departamento ?? ''),
        municipio: +(facturacionData.municipio ?? ''),
        codigo: this.componentesService.calcularDigitoChequeo(facturacionData.numeroIdentificacion)
      }
      let barrio = facturacionData.direccion.includes("ADICIONAL") ?
        facturacionData.direccion.substring(facturacionData.direccion.indexOf("BARRIO") + 7,
          facturacionData.direccion.indexOf("ADICIONAL") - 1) :
        facturacionData.direccion.includes("BARRIO") ?
          facturacionData.direccion.substring(facturacionData.direccion.indexOf("BARRIO") + 7) : ""

      let adicional = facturacionData.direccion.includes("ADICIONAL") ?
        facturacionData.direccion.substring(facturacionData.direccion.indexOf("ADICIONAL") + 10) : ""

      let tipoDireccion = "Otro"
      let tipoNumero = ""
      let numero = ""
      let numero2 = ""
      if (facturacionData.direccion.includes("#") &&
        facturacionData.direccion.includes("BARRIO")) {
        let tipoDireccionIni = facturacionData.direccion.substring(0, facturacionData.direccion.indexOf("#")).split(' ').filter((i: any) => i)
        if (tipoDireccionIni.length > 2) {
          tipoDireccion = tipoDireccionIni[0] + " " + tipoDireccionIni[1]
          tipoNumero = tipoDireccionIni[2]
        }
        else {
          tipoDireccion = tipoDireccionIni[0]
          tipoNumero = tipoDireccionIni[1]
        }

        if (tipoDireccion.length < 3) {
          tipoDireccion = "Otro"
        }

        let numeroIni = facturacionData.direccion.substring(facturacionData.direccion.indexOf("#") + 1, datosOrden.datosBasicos.direccion.indexOf("BARRIO")).trim().split('-').filter((i: any) => i)
        numero = numeroIni[0]
        numero2 = numeroIni[1]
      }
      let objetoDireccion = {
        tipo: tipoDireccion.toLocaleLowerCase().replace(/\b\w/g, first => first.toLocaleUpperCase()),
        tipoNumero: tipoNumero,
        numero: numero,
        numero2: numero2,
        barrio: barrio,
        adicional: adicional,
        tipoOtroNumero: facturacionData.direccion.includes("BARRIO") ?
          facturacionData.direccion.substring(0, facturacionData.direccion.indexOf("BARRIO")) :
          facturacionData.direccion
      }

      data.objetoDireccion = objetoDireccion
      data.direccion = objetoDireccion.tipoOtroNumero
      this.logger.log("saveOrdenPedidoFacturacionData", data)
      this.saveLocalStorage('facturacion', JSON.stringify(data));
    }

  }

  SavePagoOrdenPedidoData(detallesCertificado: any, datosBasicos: any) {
    this.logger.log("SavePagoOrdenPedidoData-detallesCertificado", detallesCertificado)
    this.logger.log("SavePagoOrdenPedidoData-datosBasicos", datosBasicos)
    var pago = {
      ordenPedidoId: this.getLocalStorage("ordenPedidoId")!,
      costoBase: detallesCertificado.valorBaseLiquidacion,
      impuestos: detallesCertificado.valorIva - detallesCertificado.valorReteIva,
      valorTotal: detallesCertificado.valorNeto,
      descuentoCupon: detallesCertificado.valorDescuentoCupon,
      mensaje: '',
      estado: 'PENDIENTE',
      url: '',
      iva: detallesCertificado.porcentajeIva,
      ica: detallesCertificado.porcentajeIca,
      reteiva: detallesCertificado.porcentajeReteIva,
      ivaValor: detallesCertificado.valorIva,
      icaValor: detallesCertificado.valorIca,
      reteIvaValor: detallesCertificado.valorReteIva,
      estampillas: detallesCertificado.estampillas,
      pagoPSE: datosBasicos.pagoPSE,
      realizoPago: datosBasicos.realizoPago,
      fechaPago: datosBasicos.fechaPago,
      referenciaPago: datosBasicos.referenciaPago ?? '',
      usuarioRegistraPago: datosBasicos.usuarioRegistraPago ?? 'N',
      documentos: [],
      esDescuentoCupon: detallesCertificado.esDescuentoCupon ?? false,
      cuponAplicado: detallesCertificado.cuponAplicado,
      cuponAplicadoExitosamente: detallesCertificado.cuponAplicadoExitosamente ?? false
    }
    this.logger.log("SavePagoOrdenPedidoData", pago)
    this.saveLocalStorage("pago", JSON.stringify(pago))
  }

  SaveEntregaOrdenPedidoData(informacionEnvio: any) {
    this.logger.log("SaveEntregaOrdenPedidoData", informacionEnvio)
    var entrega: any = {
      tipo: informacionEnvio.tipo
    }
    if (informacionEnvio.tipo === "CORREO") {
      entrega.correo = informacionEnvio.envioViaCorreoElectronico.correoElectronico.toUpperCase()
    }
    else if (informacionEnvio.tipo === "OFICINA") {
      entrega.direccionCompleta = informacionEnvio.recogeCerti.direccion

    }
    else {
      var telefono = informacionEnvio.envioDomicilio.telefono && informacionEnvio.envioDomicilio.telefono.length > 0 ?
        informacionEnvio.envioDomicilio.telefono[0] : {
          extension: "",
          indicativo: "",
          telefono: ""
        }

      let barrio = informacionEnvio.envioDomicilio.direccion.includes("ADICIONAL") ?
        informacionEnvio.envioDomicilio.direccion.substring(informacionEnvio.envioDomicilio.direccion.indexOf("BARRIO") + 7,
          informacionEnvio.envioDomicilio.direccion.indexOf("ADICIONAL") - 1) :
        informacionEnvio.envioDomicilio.direccion.includes("BARRIO") ?
          informacionEnvio.envioDomicilio.direccion.substring(informacionEnvio.envioDomicilio.direccion.indexOf("BARRIO") + 7) : ""

      let adicional = informacionEnvio.envioDomicilio.direccion.includes("ADICIONAL") ?
        informacionEnvio.envioDomicilio.direccion.substring(informacionEnvio.envioDomicilio.direccion.indexOf("ADICIONAL") + 10) : ""

      let tipoDireccion = "Otro"
      let tipoNumero = ""
      let numero = ""
      let numero2 = ""
      if (informacionEnvio.envioDomicilio.direccion.includes("#") &&
        informacionEnvio.envioDomicilio.direccion.includes("BARRIO")) {
        let tipoDireccionIni = informacionEnvio.envioDomicilio.direccion.substring(0, informacionEnvio.envioDomicilio.direccion.indexOf("#")).split(' ').filter((i: any) => i)
        if (tipoDireccionIni.length > 2) {
          tipoDireccion = tipoDireccionIni[0] + " " + tipoDireccionIni[1]
          tipoNumero = tipoDireccionIni[2]
        }
        else {
          tipoDireccion = tipoDireccionIni[0]
          tipoNumero = tipoDireccionIni[1]
        }
        if (tipoDireccion.length < 3) {
          tipoDireccion = "Otro"
        }
        let numeroIni = informacionEnvio.envioDomicilio.direccion.substring(informacionEnvio.envioDomicilio.direccion.indexOf("#") + 1, informacionEnvio.envioDomicilio.direccion.indexOf("BARRIO")).trim().split('-').filter((i: any) => i)
        numero = numeroIni[0]
        numero2 = numeroIni[1]
      }

      let objetoDireccion = {
        tipo: tipoDireccion.toLocaleLowerCase().replace(/\b\w/g, first => first.toLocaleUpperCase()),
        tipoNumero: tipoNumero,
        numero: numero,
        numero2: numero2,
        barrio: barrio,
        adicional: adicional,
        tipoOtroNumero: informacionEnvio.envioDomicilio.direccion.includes("BARRIO") ?
          informacionEnvio.envioDomicilio.direccion.substring(0, informacionEnvio.envioDomicilio.direccion.indexOf("BARRIO")) :
          informacionEnvio.envioDomicilio.direccion
      }

      entrega = {
        departamento: +informacionEnvio.envioDomicilio.departamento,
        municipio: +informacionEnvio.envioDomicilio.municipio,
        celular: informacionEnvio.envioDomicilio.celular,
        indicativo: telefono.indicativo,
        telefono: telefono.telefono,
        extension: telefono.extension,
        objetoDireccion: objetoDireccion,
        direccionCompleta: objetoDireccion.tipoOtroNumero,
        tipo: "ENVIO"
      }
    }

    this.saveLocalStorage("entrega", JSON.stringify(entrega))
    this.logger.log("SaveEntregaOrdenPedidoData", entrega)
  }

  SaveContactoOrdenPedidoData(contactoTecnico: any) {
    this.logger.log("SaveContactoOrdenPedidoData-contactoTecnico", contactoTecnico)
    var telefono = contactoTecnico.telefono && contactoTecnico.telefono.length > 0 ?
      contactoTecnico.telefono[0] : {
        extension: "",
        indicativo: "",
        telefono: ""
      }
    let contacto = {
      tipo: contactoTecnico.tipoIdentificacion,
      numero: contactoTecnico.numeroIdentificacion ?? '',
      primerNombre: contactoTecnico.primerNombre ?? '',
      segundoNombre: contactoTecnico.segundoNombre ?? '',
      primerApellido: contactoTecnico.primerApellido ?? '',
      segundoApellido: contactoTecnico.segundoApellido ?? '',
      indicativo: telefono.indicativo,
      telefono: telefono.telefono,
      extension: telefono.extension,
      celular: contactoTecnico.celular,
      correo: contactoTecnico.correoElectronico.toUpperCase(),
      correo1: contactoTecnico.correoElectronico.toUpperCase(),
      contactoOtro: false
    }
    this.logger.log("SaveContactoOrdenPedidoData", contacto)
    this.saveLocalStorage("contacto", JSON.stringify(contacto))
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  obtenerIpValor() {
    this.obtenerIp()
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta obtenerIp", res)
          // this.ip = res.ip
          // this.reintentosIp = 0;
          this.ip = "0.0.0.0"
        },
        error: (error: any) => {
          console.error('Error en subscriber obtenerIp', error)
          this.ip = "0.0.0.0"
          // if (this.reintentosIp > 5) {
          //   this.ip = "0.0.0.0"
          //   this.reintentosIp = 0;
          // }
          // else {
          //   this.reintentosIp += 1;
          //   this.obtenerIpValor()
          // }
        }
      });
  }

  obtenerParametros() {
    return this.ssps.parametros()
  }

  //TODO:implementacion de taps portal interno unica pestaña
  setupCommunication(): void {
    this.channel.onmessage = (event) => {
      if (event.data === 'new-tap?') {
        this.channel.postMessage("not-new-tap");
        this.alert.showOpenTabs('Se acaba de abrir otra pestaña de este sitio');
        setTimeout(() => {
          this.alert.closeTabs()
          this.alert.showOpenTabs('Se acaba de abrir otra pestaña de este sitio');
        }, 100);
      }
      if (event.data === "not-new-tap") {
        //alert("An instance of this site is already running");
        this.alert.showOpenTabs('Ya se está ejecutando una instancia de este sitio');
      }
    };
  }

  checkIfFirstTab(): void {
    this.channel.postMessage('new-tap?');
  }
}
