<div class=" animate__animated animate__fadeInLeftBig animate__fast">
    <div class="row" style="background-color: #f8f7f5;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);;">
        <div class="col">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-4 ">
                    <h6><strong style="color:#999696 ;">Política: </strong><strong
                            class="titulo-gradiente">{{compras.politica}}</strong>
                    </h6>
                </div>
                <div class="col-lg-5 col-md-4 col-sm-5">
                    <h6 style="float: inline-start; margin-right: 10px;"><strong style="color:#999696;">Certificado:
                        </strong><strong *ngIf="compras.dispo.toLowerCase() === 'pkcs#10'"
                            class="titulo-gradiente">{{compras.dispo | uppercase}}</strong>
                        <strong *ngIf="compras.dispo.toLowerCase() !== 'pkcs#10'"
                            class="titulo-gradiente">{{compras.dispo | titlecase}}</strong>
                    </h6>&nbsp;
                    <h6 style="float: inline-start; margin-right: 10px;"><strong style="color:#999696 ;">Vigencia:
                        </strong><strong class="titulo-gradiente">{{compras.anos}}</strong>
                    </h6>&nbsp;
                    <h6 style="float: inline-start;"><strong style="color:#999696 ;">Costo: </strong><strong
                            class="titulo-gradiente">{{costos |
                            currency:'COP':
                            'symbol' : '1.0-0' }} </strong>
                    </h6>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3" style="width: 135px;">
                    <h6><strong style="color:#999696 ;">Solicitantes: </strong><strong class="titulo-gradiente">
                            {{'1'}} </strong>
                    </h6>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3" *ngIf="noradicadopedi">
                    <h6><strong style="color:#999696 ;">No Orden de pedido: </strong><strong class="titulo-gradiente">
                            {{noradicadopedi}} </strong>
                    </h6>
                    <h6> <strong style="color:#999696 ;"></strong></h6>
                </div>
            </div>
        </div>
        <br>
        <br>
        <div *ngIf="mostrarBack" class="contiene2 quitarextos" style="border-radius: 0px;">
            <app-barra *ngIf="mostrarBarra"></app-barra>
            <br>
            <div class="flujos2 quitarextos">
                <div class="row">
                    <ng-template [ngIf]="compras.cliente == 'natural'">
                        <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12" style="margin: auto 5px auto auto;">
                            <div class="flujos" #basica>
                                <p class="lineadetiempo"><span>Datos del</span><span>solicitante</span></p>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template [ngIf]="compras.cliente == 'juridica'">
                        <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12" style="margin: auto 5px auto auto;">
                            <div class="flujos" #basica>
                                <p class="lineadetiempo"><span>Datos de</span> <span>empresa</span></p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12" style="margin: auto 5px auto 0;">
                            <div class="flujosin" #solicitante>
                                <p class="lineadetiempo"><span>Datos del</span> <span>solicitante</span></p>
                            </div>
                        </div>
                    </ng-template>
                    <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12" style="margin: auto 5px auto 0;"
                        *ngIf="compras.dispo?.toUpperCase() === 'PKCS#10'">
                        <div class="flujosin" #contacto>
                            <p class="lineadetiempo"><span>Datos</span><span>persona vinculada</span></p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12" style="margin: auto 5px auto 0;">
                        <div class="flujosin" #entregan>
                            <p class="lineadetiempo"><span>Datos de</span> <span>entrega</span></p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12" style="margin: auto 5px auto 0;">
                        <div class="flujosin" #facturacionn>
                            <p class="lineadetiempo"><span>Facturación</span> <span>electrónica</span></p>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12" style="margin: auto auto auto 0;">
                        <div class="flujosin" #pago>
                            <p class="lineadetiempo"><span>Pagar</span><span>&nbsp;</span></p>
                        </div>
                    </div>
                    <hr class="linea">

                </div>


                <div class="container">
                    <div class="row row-margenes" #margen (window:resize)="onResize($event)">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template [ngIf]="nopedido">
    <div class="alert alert-light text-center animate__animated animate__fadeIn animate__faster"
        style="margin-top: 0px; z-index: 10000;">
        <br>
        <h3>Se creó la Orden de Pedido N° {{noradicadopedi}}</h3>
        <p>Su orden de compra de certificado ha sido creada</p>
        <!-- <p *ngIf="p10Mecanismo">Su primera llave de certificado está siendo generada, revise su bandeja de correo en los próximos minutos.</p> -->
        <div class="mb-4">
            <button type="button" class="btn btn-secondary" style="margin: 15px;"
                (click)="ContinuarSinPago('Continuar sin pago')">Continuar
                sin pago</button>
            <button type="button" class="btn btn-primary " (click)="irAPago()">pagar</button>
        </div>
    </div>
</ng-template>
<ng-template [ngIf]="nopedidoActualizar">
    <div class="alert alert-light text-center animate__animated animate__fadeIn animate__faster"
        style="margin-top: 0px; z-index: 10000;">
        <br>
        <h3>Se ha actualizado la orden de pedido N° {{noradicadopedi}}</h3>
        <p class="textoc" style="font-size: 14pt; text-align: center;">Su orden de compra de certificado ha sido
            actualizada<br>
            <br>
            <span *ngIf="mostrarOpcionesValidacionIdentidad">Por tu seguridad y la del proceso, queremos validar tu
                identidad.</span>
        </p>
        <div class="mb-4" *ngIf="mostrarOpcionesPago && !mostrarOpcionesValidacionIdentidad">
            <button type="button" class="btn btn-secondary" style="margin: 15px;"
                (click)="ContinuarSinPago('Continuar sin pago')">Continuar
                sin pago</button>
            <button type="button" class="btn btn-primary " (click)="irAPago()">Pagar</button>
        </div>
        <div class="mb-4" *ngIf="!mostrarOpcionesPago && mostrarOpcionesValidacionIdentidad">
            <button type="button" class="btn btn-secondary" style="margin: 15px;"
                (click)="ContinuarSinPago('Continuar sin validar identidad')">Salir</button>
            <button type="button" class="btn btn-primary" (click)="validarIdentidad()">Validar identidad</button>
        </div>
        <div class="mb-4" *ngIf="!mostrarOpcionesPago && !mostrarOpcionesValidacionIdentidad">
            <button type="button" class="btn btn-secondary " (click)="ContinuarSinPago('Alerta')">Salir</button>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="errorOrden">
    <div class="alert alert-light text-center animate__animated animate__fadeIn animate__faster"
        style="margin-top: 0px; z-index: 10000;">
        <br>
        <h3>Error al crear la orden pedido</h3>
        <p>Ocurrió un error al procesar la solicitud, por favor intente nuevamente.</p>
        <div class="mb-4">
            <button type="button" class="btn btn-primary " (click)="crearOrdenPedido()">Reintentar</button>
        </div>
    </div>
</ng-template>