<div class="animate__animated animate__fadeIn">
    <section class="content-body margen2">
        <div class="row">
            <div class="col">
              <h4 class="mb-3">Solicitud confirmación del contenido</h4>
              <div class="margencon">
                <p>
                  Gracias por revisar la prefactura correspondiente al periodo <strong>{{periodo}}</strong>. Elige una opción para continuar:
                  <br><strong>Aceptar</strong>: Si estás de acuerdo y podemos proceder con la emisión de la factura.
                  <br><strong>Rechazar</strong>: Si necesitas hacer ajustes. Indícanos los cambios para realizar las modificaciones necesarias.
                  <br><strong>Nota:</strong> Tu decisión se registrará automáticamente y notificaremos al equipo correspondiente.
                </p>

                <form [formGroup]="approvalForm">
                  <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label class="form-label">Visualizar prefactura generada: </label>
                      <i class="fa-solid fa-file-lines fa-2xl" 
                      style="cursor: pointer; color: #4caf50; vertical-align: 0; padding-left: 5px;"
                      (click)="viewGeneratedPreInvoice()">
                      </i>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="applyReference">
                      <div class="mb-3">
                          <label class="form-label">Valor de referencia:</label>
                          <input type="text" class="form-control" [(ngModel)]="reference" placeholder=""
                            formControlName="reference" [class.is-invalid]="camposvalidos('reference')">
                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                            *ngIf="camposvalidos('reference')">El valor de referencia es requerido y no debe superar los 100 caracteres.
                          </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="applyReference">
                      <div class="mb-3">
                          <label class="form-label">Previsualizar prefactura modificada:</label>
                          <i
                          class="fa-solid fa-file-invoice fa-2xl me-2" 
                          style="cursor: pointer; color: #4caf50; vertical-align: 0; padding-left: 5px;" 
                          (click)="previewPreInvoice()"
                          ></i>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="applyRounding">
                      <div class="mb-3">
                          <label class="form-label">Valor SubTotal Requerido:</label>
                          <input type="text" class="form-control" [(ngModel)]="requiredSubtotal" placeholder=""
                            formControlName="requiredSubtotal" [class.is-invalid]="camposvalidos('requiredSubtotal')"
                            (input)="formatAccountValue($event)">
                          <div class="form-text text-danger animate__animated animate__fadeIn animate__fast"
                            *ngIf="camposvalidos('requiredSubtotal')">El subtotal esta vacío o no contiene el formato requerido.
                          </div>
                      </div>
                    </div>

                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="mb-3">
                        <label class="form-label">Nota Factura:</label>
                        <textarea class="form-control" [(ngModel)]="note"
                          placeholder="Escriba la nota de interés para la factura..."
                          formControlName="note"
                          [class.is-invalid]="camposvalidos('note')"
                          rows="3"></textarea>
                        <div class="form-text text-danger animate__animated animate__fadeIn animate__fast" 
                          *ngIf="camposvalidos('note')">Debe escribir una nota por lo menos de 8 caracteres.
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
                
                <div class="modal-footer d-flex justify-content-center align-items-center mt-3">
                  <button type="button" class="btn btn-primary" (click)="declinePreInvoice()">Rechazar</button>
                  <button type="button" class="btn btn-primary" (click)="acceptPreInvoice()" [disabled]="approvalForm.invalid">Aceptar</button>
                </div>
            </div>
        </div> 
    </section>
</div>