import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PreInvoiceParams } from 'src/app/domain/interfaces/pre-invoice.interface';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';

@Component({
  selector: 'app-prefactura',
  templateUrl: './prefactura.component.html',
  styleUrls: ['./prefactura.component.css']
})
export class PrefacturaComponent implements OnInit {

  preInvoice: PreInvoiceParams = {
    idPrefactura: ''
  };

  data: any;

  constructor(private alert: AlertsService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const decoded = atob(params.get('id') || '');
      this.preInvoice.idPrefactura = decoded;
    });

    this.alert.showAceptarPrefactura(this.preInvoice);
  }
}
