import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { DatePipe } from '@angular/common'
import { LogService } from '../../../domain/servicios/log.service'
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from "src/environments/environment"

declare const PDFObject: any;

@Component({
  selector: 'app-descargar-factura',
  templateUrl: './descargar-factura.component.html',
  styleUrls: ['./descargar-factura.component.css']
})
export class DescargarFacturaComponent implements OnInit {

  @ViewChild('inputRefNumero')
  inputRefNumero!: { nativeElement: any; };
  @ViewChild('inputRefFactura')
  inputRefFactura!: { nativeElement: any; };
  formaForm!: FormGroup;
  usuarioFactura: any = {
    ip: '',
    tipo: '',
    numero: '',
    ordenPedidoId: '',
    factura: '',
    codigo: '',
    filtro: ''
  };
  comas = false
  consulta = false
  documento: any
  listadoFacturas: any = []
  constructor(private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private alert: AlertsService,
    public datepipe: DatePipe,
    private logger: LogService,
    private el: ElementRef,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private sanitizer: DomSanitizer
  ) {
    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      tipo: ['', [Validators.required]],
      numero: ['', [Validators.required]],
      codigo: ['', [
        Validators.required,
        Validators.min(0),
        Validators.max(9),
        , Validators.pattern("^[0-9]*$")
      ]
      ],
      filtro: ['', [Validators.required]],
      ordenPedidoId: ['', [Validators.required,
      Validators.minLength(7),
      Validators.maxLength(7),
        , Validators.pattern("^[0-9]*$")
      ]],
      factura: ['', [Validators.required,
      Validators.minLength(6),
      Validators.pattern('[a-zA-Z0-9]{6,30}')]]
    }, {
      validators: [
      ]
    });
    this.setOnChangesInputs()
  }

  async ngOnInit(): Promise<void> {
    this.ssps.obtenerIpValor()
    if (this.ssps.getLocalStorage("usuarioFactura")) {
      this.usuarioFactura = JSON.parse(this.ssps.getLocalStorage("usuarioFactura")!)
    }
    this.formaForm.controls["codigo"]?.disable({ onlySelf: true })
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  setOnChangesInputs() {
    this.formaForm.get("numero")!.valueChanges.subscribe(val => {
      if (this.inputRefNumero && val) {
        if (val.length > 6) {
          this.usuarioFactura.codigo = this.componentesService.calcularDigitoChequeo(val)
        }
        else{
          this.usuarioFactura.codigo = null
        }
        const elRef = this.inputRefNumero?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numero")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("factura")!.valueChanges.subscribe(val => {
      if (this.inputRefFactura) {
        const elRef = this.inputRefFactura?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("factura")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }

  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.get(campo)!.invalid &&
      (this.formaForm.get(campo)!.touched || this.formaForm.get(campo)!.dirty);
  }

  //TODO:cerrar el modal de activo digital
  close() {
    this.alert.closesSpiner();
  }

  /*ingreso y validacion de datos de formulario*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          this.logger.log("ngsubmit", this.formaForm.controls[key].errors)
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    else {
      //this.estados= true;
      this.alert.showSpiner("Estamos cargando sus solicitudes, este proceso puede tardar un momento");
      this.logger.log("consultar", this.usuarioFactura)
      this.validarCaptcha()
    }
  }

  validarCaptcha() {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {
        this.ssps.validatedCapcha(token)
          .subscribe({
            next: (res: any) => {
              this.logger.log("respuesta captcha", res)
              if (res.code == 200) {
                this.consultarFactura()
              }
            },
            error: (error) => {
              this.alert.closesSpiner()
              console.error('Error en la funcion de capcha', error);
              let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
                error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                  error.error.message ? error.error.message :
                    (error.message ? error.message : "No fue posible realizar la petición")) :
                (error.message ? error.message : "No fue posible realizar la petición")
              this.alert.showError(mensaje, false, null, false, null, null, false)
            },
          })
      });
  }

  consultarFactura() {
    let data = {
      numeroFactura: this.usuarioFactura.factura,
      ordenPedidoId: this.usuarioFactura.ordenPedidoId === "" ? 0 : this.usuarioFactura.ordenPedidoId,
      numeroIdentificacion: this.usuarioFactura.numero === "" ? null :
        (this.usuarioFactura.numero + (this.usuarioFactura.tipo === "NIT" ?
          this.usuarioFactura.codigo : ""))
    }
    this.ssps.consultarFactura(data)
      .subscribe({
        next: (res: any) => {
          this.logger.log("consultarFactura", res)
          this.listadoFacturas = res.data.response
          this.alert.closesSpiner()
          if (res.data.response === null || res.data.response === undefined ||
            res.data.response.length === 0
          ) {
            this.alert.showError("No se encontraron facturas disponibles", false, null, false, null, null, false)
          }
        },
        error: (error) => {
          console.error('Error en subscriber consultarFactura', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.consultarFactura();
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber consultarFactura', error)
            let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
              error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                error.error.message ? error.error.message :
                  (error.message ? error.message : "No fue posible realizar la petición")) :
              (error.message ? error.message : "No fue posible realizar la petición")
            this.alert.showError(mensaje, false, null, false, null, null, false)
          }
        }
      })
  }

  cancelarSeleccion() {
    this.usuarioFactura.tipo = ""
    this.usuarioFactura.numero = ""
    this.usuarioFactura.codigo = ""
    this.usuarioFactura.ordenPedidoId = ""
    this.usuarioFactura.factura = ""
    this.usuarioFactura.filtro = ''
    this.consulta = false
    this.documento = null
    this.listadoFacturas = []
  }

  cancelarSeleccionDocumento() {
    this.consulta = false
    this.documento = null
  }

  changeFiltro($event: any) {
    let filtro = $event.target.value;
    this.usuarioFactura.tipo = ""
    this.usuarioFactura.numero = ""
    this.usuarioFactura.codigo = ""
    this.usuarioFactura.ordenPedidoId = ""
    this.usuarioFactura.factura = ""
    this.quitarValidaciones(filtro)
  }

  changeTipoDocumento($event: any) {
    this.usuarioFactura.numero = ""
    this.usuarioFactura.codigo = ""
    let tipoDocumento = $event.target.value;
    this.logger.log("changeTipoDocumento", tipoDocumento)
    const control = this.formaForm.get('codigo');
    if (control) {
      this.formaForm.clearValidators()
      control.clearValidators();
      this.logger.log("changeTipoDocument-2o", tipoDocumento)
      if (tipoDocumento !== "NIT") {
        control.setValue("")
        control.updateValueAndValidity();
        this.formaForm.addValidators([this.componentesService.codigonit('tipo', 'numero')])
        this.formaForm.updateValueAndValidity()
      }
      else {
        control.addValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(9),
          Validators.pattern("^[0-9]*$")
        ])
        control.setValue("")
        control.updateValueAndValidity();
        this.formaForm.addValidators([this.componentesService.codigonit('tipo', 'numero'),
        this.componentesService.digitoChequeo('numero', 'codigo')])
        this.formaForm.updateValueAndValidity()
      }
    }
  }

  quitarValidaciones(tipoFiltro: any) {
    this.logger.log("quitarValidaciones", tipoFiltro)
    const controles = ['tipo', 'numero',
      'codigo', 'ordenPedidoId', 'factura'];
    // Iterar sobre los nombres de los controles y eliminar los validadores
    for (const controlName of controles) {
      this.formaForm.clearValidators()
      const control = this.formaForm.get(controlName);
      if (control) {
        control.clearValidators();
        if (tipoFiltro === "DOCUMENTO") {
          if (controlName === "tipo") {
            control.addValidators([
              Validators.required
            ])
          }
          else if (controlName === "numero") {
            control.addValidators([
              Validators.required
            ])
          }
          else if (controlName === "codigo") {
            control.addValidators([
              Validators.required,
              Validators.min(0),
              Validators.max(9),
              Validators.pattern("^[0-9]*$")
            ])
          }
          this.formaForm.addValidators([this.componentesService.codigonit('tipo', 'numero'),
          this.componentesService.digitoChequeo('numero', 'codigo')])
        }
        else if (tipoFiltro === "ORDEN" && controlName === "ordenPedidoId") {
          control.addValidators([Validators.required,
          Validators.minLength(7),
          Validators.maxLength(7), Validators.pattern("^[0-9]*$")
          ])
          this.formaForm.clearValidators()
        }
        else if (tipoFiltro === "FACTURA" && controlName === "factura") {
          control.addValidators([Validators.required,
          Validators.minLength(6),
          Validators.pattern('[a-zA-Z0-9]{6,30}')])
          this.formaForm.clearValidators()
        }
        control.setValue("")
        control.updateValueAndValidity();
        this.formaForm.updateValueAndValidity()
      }
    }
  }

  noPuntoComa(event: any) {

    var e = event || window.event;
    var key = e.keyCode || e.which;


    if (key === 110 || key === 190 || key === 188) {

      this.comas = true;
    } else {
      this.comas = false;
    }
  }

  /*quitar numeros ceros a la izquierda*/
  quitarceros(numero: string) {

    this.usuarioFactura.numero = numero.replace(/^0+/, '');
  }

  verFactura(documentoFactura: any) {
    this.alert.showSpiner("Cargando documento, por favor espere");
    //this.documento = res.data.response.documento
    //this.documento = this.sanitizer.bypassSecurityTrustUrl(base64Source)
    this.consulta = true
    this.documento = 'data:application/pdf;base64,' + documentoFactura.documento
    setTimeout(() => {
      PDFObject.embed(this.documento, '#pdfContainer');
      this.alert.closesSpiner()
    }, 3000);
  }

  closeModal() {
    let externalUrl = `${environment.urlWebCerti}`;
    this.alert.closesFiels();
    localStorage.clear()
    window.location.replace(externalUrl);
  }

}