<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">¡Gracias por su confirmación!</h5>
		<button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
	</div>
  <div class="modal-body">
    <img src="../../../assets/exito.PNG" class="rounded mx-auto d-block" style="height:80%;" width="80%">
 <p class="textoc">
    Hemos registrado su rechazo de la prefactura enviada. 

    Procederemos a revisar y realizar los ajustes necesarios para generar una nueva prefactura para el periodo {{periodo}}, la cual se enviará por el mismo mecanismo.
    
 </p>
</div>
	<div class="modal-footer">
		<button type="button" 
            class="btn btn-secondary" 
            (click)="close()">Aceptar</button>
</div>