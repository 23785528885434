import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {

  @ViewChild('modalHeader', { static: false }) modalHeader!: ElementRef;
  @ViewChild('pdfViewer', { read: ElementRef, static: false }) pdfViewer!: ElementRef<any>;
  @Input() data: any;

  constructor(private alert: AlertsService
              ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.pdfViewer.nativeElement.src = this.data;
    this.makeModalDraggable();
  }

  //TODO:cierra ventana de visores
  cerrar() {
    this.alert.closePDF();
  }

  //TODO: movimiento de PDF en la pantalla
  makeModalDraggable() {
    const modalDialog = this.modalHeader.nativeElement.closest('.modal-dialog');

    let isDragging = false;
    let startX = 0, startY = 0, initialMouseX = 0, initialMouseY = 0;

    this.modalHeader.nativeElement.addEventListener('mousedown', (e: MouseEvent) => {
      isDragging = true;
      startX = modalDialog.offsetLeft;
      startY = modalDialog.offsetTop;
      initialMouseX = e.clientX;
      initialMouseY = e.clientY;

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    });

    const onMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;

      const dx = e.clientX - initialMouseX;
      const dy = e.clientY - initialMouseY;
      modalDialog.style.top = startY + dy + 'px';
      modalDialog.style.left = startX + dx + 'px';
    };

    const onMouseUp = () => {
      isDragging = false;
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }

}
