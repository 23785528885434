import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AlertsService } from 'src/app/domain/servicios/alerts.service';
import { ComponentesService } from 'src/app/domain/servicios/componentes.service';
import { SspsService } from 'src/app/domain/servicios/ssps.service';
import { DatePipe } from '@angular/common'
import { LogService } from '../../../domain/servicios/log.service'
import { urlRequest } from 'src/assets/config/app.conf';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { EBiometricsEnvironment, startFlow } from '@softcol/biometrics';

declare var bootstrap: any;

@Component({
  selector: 'app-validacion-identidad',
  templateUrl: './validacion-identidad.component.html',
  styleUrls: ['./validacion-identidad.component.css']
})
export class ValidacionIdentidadComponent implements OnInit {
  @ViewChild('inputRefNombres')
  inputRefNombres!: { nativeElement: any; };

  @ViewChild('inputRefApellidos')
  inputRefApellidos!: { nativeElement: any; };

  @ViewChild('inputRefNumero')
  inputRefNumero!: { nativeElement: any; };
  @Input() public urlRedireccionFlujoValida: any
  @Input() public botonFinalizaIdentidad: any
  urlRequest = urlRequest
  formaForm!: FormGroup;
  solicitudId: any = ""
  fechaExpDocumento!: any;
  statusFechaExpDocumento: boolean = false;
  depar: any[] = [];
  muni: any[] = [];
  munifi: any[] = [];
  usuarioValida: any = {
    ip: '',
    idSolicitudTitular: 0,
    tipo: '',
    numero: '',
    departamento: '',
    municipio: '',
    nombres: '',
    apellidos: '',
    correo: '',
    celular: '',
    fechaExpDocumento: null
  };
  preguntas: any[] = [];
  carousel: any;
  ultimaRespuesta = -1
  disableSiguiente = true;
  respuesta: any = null
  actual = 0
  maxDate: NgbDateStruct
  minDate: NgbDateStruct
  tipoValidacion = ""
  inicioFacial = false
  fechaExpDocumentoFormato = ""
  applicationId: any;
  timer: any
  facialId = ""
  callbackEbiometrics = false

  constructor(private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private componentesService: ComponentesService,
    private ssps: SspsService,
    private alert: AlertsService,
    public datepipe: DatePipe,
    private logger: LogService,
    private el: ElementRef
  ) {
    /*validacion de campos validators*/
    this.formaForm = this.fb.group({
      tipo: ['', [Validators.required]],
      numero: ['', [Validators.required,
        // Validators.pattern('([A-Za-z]+[0-9]|[0-9]+[A-Za-z])[A-Za-z0-9]*')
      ]],
      nombres: ['', [Validators.pattern("^[a-zA-Z ]{2,60}$"),
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(60)
      ]
      ],
      fechaExpDocumento: ['', [Validators.required]],
      apellidos: ['', [Validators.required,
      Validators.minLength(2),
      Validators.maxLength(60),
      Validators.pattern('[a-zA-Z ]{2,60}')]],
      departamento: [''],
      municipio: [''],
    }, { validators: [this.componentesService.codigonit('tipo', 'numero')] });
    this.setOnChangesInputs()
    var actualFecha = new Date();
    this.maxDate = { year: actualFecha.getFullYear(), month: actualFecha.getMonth() + 1, day: actualFecha.getDate() }
    this.minDate = { year: actualFecha.getFullYear() - 100, month: actualFecha.getMonth() + 1, day: actualFecha.getDate() }
  }

  ngOnInit(): void {
    this.logger.log("tipoValidacion", this.tipoValidacion)
    this.alert.closesSpiner()
    this.ssps.obtenerIpValor()
    this.logger.log("validacionIdentidad-redireccion", this.urlRedireccionFlujoValida)
    //this.departamentos()
    if (this.ssps.getLocalStorage("solicitudId")) {
      this.solicitudId = JSON.parse(this.ssps.getLocalStorage("solicitudId")!)
      var usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
      this.usuarioValida.ip = usuario.ip
      this.usuarioValida.idSolicitudTitular = usuario.idTitular
      this.fechaExpDocumento = usuario.fechaExpDocumento !== undefined &&
        usuario.fechaExpDocumento !== null ? usuario.fechaExpDocumento : ""
      this.usuarioValida.correo = usuario.correo
      this.usuarioValida.celular = usuario.celular
      this.usuarioValida.tipo = usuario.tipo
      this.usuarioValida.numero = usuario.numero
      this.usuarioValida.nombres = (usuario.primerNombre ?? '') + (usuario.segundoNombre !== null &&
        usuario.segundoNombre !== undefined && usuario.segundoNombre !== "" ?
        " " + usuario.segundoNombre : "")
      this.usuarioValida.apellidos = (usuario.primerApellido ?? '') + (usuario.segundoApellido !== null &&
        usuario.segundoApellido !== undefined && usuario.segundoApellido !== "" ?
        " " + usuario.segundoApellido : "")

      if (usuario.identidadValidada === true) {
        this.ssps.saveLocalStorage("validaIdentidad", "true")
        this.alert.showError("Validación de identidad realizada exitosamente.", true, "¡Validación de identidad exitosa!",
          true, this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad, false)
      }
      if (this.ssps.getLocalStorage("tipocliente") === "natural") {
        this.usuarioValida.tipo = usuario.tipo
        this.usuarioValida.nombres = (usuario.primerNombre ?? '') + (usuario.segundoNombre !== null &&
          usuario.segundoNombre !== undefined && usuario.segundoNombre !== "" ?
          " " + usuario.segundoNombre : "")
        this.usuarioValida.apellidos = (usuario.primerApellido ?? '') + (usuario.segundoApellido !== null &&
          usuario.segundoApellido !== undefined && usuario.segundoApellido !== "" ?
          " " + usuario.segundoApellido : "")


        this.fechaExpDocumento = usuario.fechaExpDocumento !== undefined &&
          usuario.fechaExpDocumento !== null ? usuario.fechaExpDocumento : ""
      }
      else if (this.ssps.getLocalStorage("tipocliente") === "juridica") {
        var solicitante = JSON.parse(this.ssps.getLocalStorage('solicitante')!);
        this.usuarioValida.tipo = solicitante.tipo
        this.usuarioValida.numero = solicitante.numero
        this.usuarioValida.nombres = (solicitante.primerNombre ?? '') + (solicitante.segundoNombre !== null &&
          solicitante.segundoNombre !== undefined && solicitante.segundoNombre !== "" ?
          " " + solicitante.segundoNombre : "")
        this.usuarioValida.apellidos = (solicitante.primerApellido ?? '') + (solicitante.segundoApellido !== null &&
          solicitante.segundoApellido !== undefined && solicitante.segundoApellido !== "" ?
          " " + solicitante.segundoApellido : "")
        this.fechaExpDocumento = solicitante.fechaExpDocumento !== undefined &&
          solicitante.fechaExpDocumento !== null ? solicitante.fechaExpDocumento : ""
      }
      this.mapearDatosForm()
      this.logger.log("tipoValidacion", this.tipoValidacion)
    }
    else {
      this.alert.showError("Datos de validación incorrectos", true, null, false, this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad, false)
    }
    //this.OnChanges()
  }

  mapearDatosForm() {
    this.formaForm.controls['tipo'].disable({ onlySelf: true });
    this.formaForm.controls['numero'].disable({ onlySelf: true });
    this.formaForm.controls['nombres'].disable({ onlySelf: true });
    this.formaForm.controls['apellidos'].disable({ onlySelf: true });
  }

  mapearAllDatosForm() {
    this.formaForm.controls['tipo'].disable({ onlySelf: true });
    this.formaForm.controls['numero'].disable({ onlySelf: true });
    this.formaForm.controls['nombres'].disable({ onlySelf: true });
    this.formaForm.controls['apellidos'].disable({ onlySelf: true });
    this.formaForm.controls['fechaExpDocumento'].disable({ onlySelf: true });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  setOnChangesInputs() {
    this.formaForm.get("nombres")!.valueChanges.subscribe(val => {
      if (this.inputRefNombres) {
        const elRef = this.inputRefNombres?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val.toUpperCase();
        this.formaForm.get("nombres")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("apellidos")!.valueChanges.subscribe(val => {
      if (this.inputRefApellidos) {
        const elRef = this.inputRefApellidos?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("apellidos")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });

    this.formaForm.get("numero")!.valueChanges.subscribe(val => {
      if (this.inputRefNumero) {
        const elRef = this.inputRefNumero?.nativeElement;
        const orVal = elRef?.getAttribute('data-model-value') || '';
        val = val?.replace(orVal.toUpperCase(), orVal);
        elRef?.setAttribute('data-model-value', val);
        elRef.value = val?.toUpperCase();
        this.formaForm.get("numero")!.setValue(val.toUpperCase(), {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }

  /* funcion para validacion de campos*/
  camposvalidos(campo: any) {
    return this.formaForm.get(campo)!.invalid &&
      (this.formaForm.get(campo)!.touched || this.formaForm.get(campo)!.dirty);
  }

  //TODO:cerrar el modal de activo digital
  close() {
    this.alert.closesSpiner();
  }

  /*ingreso y validacion de datos de formulario*/
  ngsubmit() {
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      Object.keys(this.formaForm.controls).forEach(key => {
        if (this.formaForm.controls[key].errors) {
          this.logger.log(key, this.formaForm.controls[key].errors)
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl?.focus();
          return;
        }
      });
      return;
    }
    else {
      //this.estados= true;
      this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso");
      this.consultarPreguntas(this.usuarioValida.nombres, this.usuarioValida.apellidos, this.usuarioValida.tipo,
        this.usuarioValida.numero, this.fechaExpDocumento, this.usuarioValida.municipio,
        this.usuarioValida.departamento, this.usuarioValida.ip, this.usuarioValida.idSolicitudTitular);
    }
  }

  ngsubmitFacial() {
    this.logger.log("ngsubmitFacial", this.fechaExpDocumento)
    if (this.formaForm.invalid) {
      this.formaForm.markAllAsTouched();
      return;
    }
    else {
      let { fechaExpDocumento } = this.formaForm.value;
      this.usuarioValida.fechaExpDocumento = fechaExpDocumento
      var arrayFechaExp = fechaExpDocumento.split("/")
      this.fechaExpDocumentoFormato = arrayFechaExp[2] + "-" + arrayFechaExp[1] + "-" + arrayFechaExp[0]
      this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso");
      //Validar intentos primero
      this.consultarIntentos(true)
    }
  }

  iniciarFlujoValidacionFacial() {
    this.inicioFacial = true
    startFlow({
      target: 'ebm',
      account: environment.accountValidacionFacial,
      environment: environment.production ? EBiometricsEnvironment.production :
        EBiometricsEnvironment.development,
      params: {
        name: this.usuarioValida.nombres,
        lastName: this.usuarioValida.apellidos,
        documentType: this.usuarioValida.tipo,
        documentNumber: +this.usuarioValida.numero,
        documentDateOfIssue: this.fechaExpDocumentoFormato,
        countryCode: '+57',
        phone: +this.usuarioValida.celular,
        email: this.usuarioValida.correo,
      },
      onError: (err) => {
        clearInterval(this.timer);
        this.callbackEbiometrics = true
        this.logger.error("onError startFlow", err)
        this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso")
        this.guardarIntentoFacial(JSON.stringify(err.message), false)
        let errMessage = JSON.parse(JSON.stringify(err.message))
        var message = err.message
        if (errMessage.code !== undefined && errMessage.code !== null) {
          message = errMessage.code
        }
        this.alert.showError(message, true, "¡Error validación de identidad!", false,
          this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad, false)
        this.inicioFacial = false
      },
      onSuccess: (data) => {
        clearInterval(this.timer);
        this.callbackEbiometrics = true
        this.logger.error("onSuccess startFlow", data)
        this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso")
        this.guardarIntentoFacial(data.id, true)
      },
      onRetry: () => {
        this.logger.error("onRetry", "startFlow")
      },
    });
  }

  consultarPreguntas(nombres: string, apellidos: string, tipo: string, numero: string, fechaExpedicion: string,
    municipio: string, departamento: string, ip: string, solicitudTitularId: number) {
    this.ssps.preguntasValidacion(nombres, apellidos, tipo, numero, fechaExpedicion,
      municipio, departamento, ip, solicitudTitularId)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta preguntas", res)
          this.preguntas = res.preguntas
          this.applicationId = res.applicationId
          setTimeout(() => {
            this.alert.closesSpiner()
            this.carousel = new bootstrap.Carousel(this.el.nativeElement.querySelector('#carouselExampleControlsNoTouching'));
            this.carousel.to(this.actual);
          }, 500);
          if (this.ssps.getLocalStorage("tipocliente") === "natural") {
            var usuario = JSON.parse(this.ssps.getLocalStorage('usuario')!);
            usuario.fechaExpDocumento = fechaExpedicion
            this.ssps.saveLocalStorage('usuario', JSON.stringify(usuario));
          }
          else if (this.ssps.getLocalStorage("tipocliente") === "juridica") {
            var solicitante = JSON.parse(this.ssps.getLocalStorage('solicitante')!);
            solicitante.fechaExpDocumento = fechaExpedicion
            this.ssps.saveLocalStorage('solicitante', JSON.stringify(solicitante));
          }
        },
        error: (error) => {
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.consultarPreguntas(nombres, apellidos, tipo, numero, fechaExpedicion,
              municipio, departamento, ip, solicitudTitularId);
          }
          else if (error.status === 429 && this.tipoValidacion === "PREGUNTAS") {
            this.tipoValidacion = "FACIAL";
            this.inicioFacial = false;
            this.alert.closesSpiner()
            this.alert.showError("No fue posible validar su identidad por medio de preguntas de seguridad, realice su validación de identidad mediante reconocimiento facial.",
              false, "¡Validación de identidad no exitosa!", false, this.urlRedireccionFlujoValida,
              this.botonFinalizaIdentidad !== null ? this.botonFinalizaIdentidad : "Aceptar", false)
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber preguntasValidacion', error)
            let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
              error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                error.error.message ? error.error.message :
                  (error.message ? error.message : "No fue posible realizar la petición")) :
              (error.message ? error.message : "No fue posible realizar la petición")
            this.alert.showError(mensaje, this.urlRedireccionFlujoValida !== "" && this.urlRedireccionFlujoValida !== null, null, false, this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad, false)
          }
        }
      })
  }

  consultarIntentos(iniciarFacial: boolean) {
    let data = {
      numeroDocumento: this.usuarioValida.numero,
      fuente: this.tipoValidacion,
      solicitudTitularId: this.usuarioValida.idSolicitudTitular
    }
    this.ssps.consultarIntentosValidacion(data)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta consultarIntentosValidacion", res)
          if (iniciarFacial) {
            this.iniciarFlujoValidacionFacial()
            //Inciar flujo de validación de texto
            clearInterval(this.timer);
            this.timer = setInterval(() => {
              this.validarTagExitosoFacial()
            }, 10000);
            setTimeout(() => {
              this.alert.closesSpiner()
            }, 2500);
          }
          else {
            this.alert.closesSpiner()
          }
        },
        error: (error) => {
          //Si da un  codigo de error 429, mostrar alerta y validar si puede pasar al siguiente metodo o no
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.consultarIntentos(iniciarFacial);
          }
          else if (error.status === 429 && this.tipoValidacion === "FACIAL") {
            this.tipoValidacion = this.tipoValidacion === "FACIAL" ? "PREGUNTAS" : "FACIAL";
            this.inicioFacial = false;
            this.alert.closesSpiner()
            this.alert.showError("No fue posible validar su identidad por medio de reconocimiento facial, realice su validación de identidad mediante preguntas de seguridad.",
              false, "¡Validación de identidad no exitosa!", false, this.urlRedireccionFlujoValida, "Aceptar", false)
          }
          else {
            this.alert.closesSpiner()
            console.error('Error en subscriber preguntasValidacion', error)
            let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
              error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                error.error.message ? error.error.message :
                  (error.message ? error.message : "No fue posible realizar la petición")) :
              (error.message ? error.message : "No fue posible realizar la petición")
            this.alert.showError(mensaje, this.urlRedireccionFlujoValida !== "" && this.urlRedireccionFlujoValida !== null, null, false, this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad, false)
          }
        }
      })
  }

  validarTagExitosoFacial() {
    const divebm = document.getElementById("ebm");
    var iFrameBody = divebm?.getElementsByTagName("iframe")[0];
    if (divebm && iFrameBody) {
      if (this.facialId === "") {
        this.facialId = iFrameBody?.src.substring(iFrameBody?.src.indexOf("id=") + 3)
      }
      else {
        this.consultarEstadoEBiometrics()
      }
    }
  }

  consultarEstadoEBiometrics() {
    this.ssps.consultarEstadoEBiometrics(this.facialId)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta consultarEstadoEBiometrics", res)
          if (res.status.value.toLowerCase() === "complete" && !this.callbackEbiometrics) {
            clearInterval(this.timer)
            //Si no ha sido guardado aún la respuesta, entonces guardar la respuesta
            this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso")
            this.guardarIntentoFacial(this.facialId, true)
          }
        },
        error: (error) => {
          console.error('Error en subscriber consultarEstadoEBiometrics', error)
        }
      })
  }

  guardarIntentoFacial(respuestaServicio: any, exitoso: boolean) {
    let data = {
      numeroDocumento: this.usuarioValida.numero,
      fuente: this.tipoValidacion,
      solicitudTitularId: this.usuarioValida.idSolicitudTitular,
      exitoso: exitoso,
      respuesta: respuestaServicio
    }
    this.ssps.guardarIntento(data)
      .subscribe({
        next: (res: any) => {
          this.callbackEbiometrics = false
          this.facialId = ""
          this.logger.log("respuesta guardarIntento", res)
          this.alert.closesSpiner()
          if (exitoso) {
            this.ssps.saveLocalStorage("validaIdentidad", "true")
            this.alert.showError("¡Validación de identidad exitosa!", false, "Proceso exitoso", true, this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad, false)
            this.inicioFacial = false
          }
        },
        error: (error) => {
          this.callbackEbiometrics = false
          console.error('Error en subscriber guardarIntento', error)
          if ((error.error && error.error.message
            && error.error.message.includes("timed out")) ||
            (error.message && error.message.includes("timed out"))) {
            this.logger.log("Error", "time out")
            this.guardarIntentoFacial(respuestaServicio, exitoso);
          }
          else {
            this.alert.closesSpiner()
            let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
              error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
                error.error.message ? error.error.message :
                  (error.message ? error.message : "No fue posible realizar la petición")) :
              (error.message ? error.message : "No fue posible realizar la petición")
            this.alert.showError(mensaje, true, null, false, this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad, false)
          }
        }
      })
  }

  //TODO:redireciona al home de certicamara
  homeNavigate() {
    this.alert.closesFiels();
    this.alert.showAlertaSalirValidarIdentidad("", false, null, "form", this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad)
  }

  cerrarModalFacial() {
    clearInterval(this.timer)
    this.facialId = ""
    this.alert.closesFiels();
    this.alert.showAlertaSalirValidarIdentidad("Podrá  realizar otro intento de validación de identidad, el día de mañana.",
      true, this.usuarioValida, "form", this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad)
  }

  fechas(dato: any, campo: any) {
    if (dato === undefined || dato === null) return;
    let valida = /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/-](?:0?[1-9]|1[0-2])|(?:29|30)[/-](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/-](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/-]0?2[/-](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/;
    let errores: boolean = false;
    if (campo === "fechaExpDocumento") {
      if (valida.test(dato) == false) {
        this.statusFechaExpDocumento = true;
      } else {
        this.statusFechaExpDocumento = false;
      }
    }

  }

  departamentos() {
    this.ssps.departamentos()
      .subscribe((res: any) => {
        this.muni = res.sort((a: any, b: any) => (a.Departamento < b.Departamento ? -1 : 1));
        this.depar = [...new Map(res.map((item: { [x: string]: any; }) => [item['Departamento'], item])).values()].sort((a: any, b: any) => (a.Departamento < b.Departamento ? -1 : 1));
        setTimeout(() => {
          this.formaForm.get("departamento")!.setValue(this.usuarioValida.departamento, {
            emitEvent: true,
            emitModelToViewChange: true
          });
        }, 1000);
      });
  }
  OnChanges() {
    this.formaForm.valueChanges.subscribe(valores => {
      let valor: number = valores.departamento;
      this.munifi = this.muni.filter(function (obj: any, index) {
        return obj.codigo_dep == valor;
      }).sort((a, b) => (a.Municipio < b.Municipio ? -1 : 1))
    });
  }

  anteriorPregunta() {
    this.logger.log("anterior pregunta", this.preguntas)
    this.actual -= 1
    this.respuesta = this.preguntas[this.actual].respuestas.filter((f: { seleccionada: any; }) => f.seleccionada)
    this.carousel.to(this.actual);
    this.preguntas[this.actual].respuestas.map((resp: any, i: any) => {
      if (resp.seleccionada === true) {
        this.ultimaRespuesta = i
      }
      return null
    });
    this.disableSiguiente = false
  }
  siguientePregunta() {
    this.logger.log("Siguiente pregunta", this.respuesta)
    if (this.respuesta === null) {
      this.logger.log("Siguiente pregunta respuesta", "Sin responder")
    }
    else {
      //Colocar todas las respuestas campo seleccionada en false y luego en true la seleccionada
      this.respuesta = null
      this.actual += 1
      this.disableSiguiente = true
      this.carousel.to(this.actual);
      this.ultimaRespuesta = -1
    }
  }

  radiosRespuesta($event: any, index: number) {
    this.logger.log("radiosRespuesta event", $event.target.value)
    this.logger.log("radiosRespuesta index", index)
    this.logger.log("radiosRespuesta ultimaRespuesta", this.ultimaRespuesta)
    if (this.ultimaRespuesta !== -1) {
      this.preguntas[this.actual].respuestas[this.ultimaRespuesta].seleccionada = false
    }
    this.preguntas[this.actual].respuestas[index].seleccionada = true
    this.ultimaRespuesta = index
    this.respuesta = this.preguntas[this.actual].respuestas[index]
    this.respuesta.seleccionada = true
    this.disableSiguiente = false
  }

  enviarRespuestas() {
    this.alert.showSpiner("No olvides tener tu documento de identificación a la mano para poder continuar con el proceso");
    this.logger.log("enviarRespuestas", this.preguntas)
    var respuestaServicio = {
      "preguntas": this.preguntas,
      "ip": this.usuarioValida.ip,
      "solicitudTitularId": this.usuarioValida.idSolicitudTitular,
      "applicationId": this.applicationId
    }
    this.ssps.respuestasValidacion(respuestaServicio)
      .subscribe({
        next: (res: any) => {
          this.logger.log("respuesta preguntas", res)
          this.alert.closesSpiner()
          this.logger.log("respuesta this.urlRedireccionFlujoValida", this.urlRedireccionFlujoValida)
          this.logger.log("respuesta this.botonFinalizaIdentidad", this.botonFinalizaIdentidad)
          this.ssps.saveLocalStorage("validaIdentidad", "true")
          this.alert.showError(res.mensaje, true, "Proceso exitoso", true, this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad, false)
          this.preguntas = []
          this.ultimaRespuesta = -1
          this.actual = 0
          this.disableSiguiente = true
          this.respuesta = null
        },
        error: (error) => {
          console.error('Error en subscriber respuestasValidacion', error)
          let mensaje = error.error ? (error.error.mensaje ? error.error.mensaje :
            error.error.descripcionErrores ? error.error.descripcionErrores.join("\n") :
              error.error.message ? error.error.message :
                (error.message ? error.message : "No fue posible realizar la petición")) :
            (error.message ? error.message : "No fue posible realizar la petición")
          this.alert.closesSpiner()
          this.alert.showError(mensaje, true, "¡Validación de identidad fallida!", false, this.urlRedireccionFlujoValida, this.botonFinalizaIdentidad, false)
          this.preguntas = []
          this.ultimaRespuesta = -1
          this.actual = 0
          this.disableSiguiente = true
          this.respuesta = null

          //Validar si el codigo de error es 429, entonces validar si el tipoValidacion es igual
          //al del env mostrar mensaje de error de que no fue posible la validacion
          //Sino pasar a validacion facial
        }
      })
  }

  seleccionarTipoValidacion(tipoValidacion: string) {
    this.tipoValidacion = tipoValidacion
    this.logger.log("seleccionarTipoValidacion", this.usuarioValida)
    //Si la fecha de expedidición ya está diligenciada, llamar al ngSubmit correspondiente
    if (this.fechaExpDocumento !== "" &&
      this.fechaExpDocumento !== undefined && this.fechaExpDocumento !== null
    ) {
      setTimeout(() => {
        this.mapearAllDatosForm()
        if (tipoValidacion === "PREGUNTAS") {
          this.logger.log("preguntas", "ingresa")
          this.ngsubmit()
        }
        else
          this.ngsubmitFacial()
      }, 1000);
    }
  }
}