export const environment = {
  production: false,
  logs: true,
  gatewayAWS: 'https://w5gyxh8ayc.execute-api.us-east-2.amazonaws.com/dev',
  gatewayFact: 'https://r07cv85c6f.execute-api.us-east-2.amazonaws.com/dev',
  gatewayDownloadFile: 'https://y1si3m2vh4.execute-api.us-east-2.amazonaws.com/dev',
  ordenPedidoserviceEC2: 'https://ventaservice.tst.certicamara.com:4040',
  certificadoserviceEC2: 'https://ventaservice.tst.certicamara.com:5051',
  validacionIdentidadserviceEC2: 'https://ventaservice.tst.certicamara.com:4041',
  listasEC2: 'https://conectorservice.tst.certicamara.com:9201',
  captchaEC2: 'https://conectorservice.tst.certicamara.com:8301',
  prefacturaServiceEC2: 'https://ventaservice.tst.certicamara.com:4042',
  consumosServiceEC2: 'https://ventaservice.tst.certicamara.com:5052',
  ordenPedidoServiceEC2: 'https://ventaservice.tst.certicamara.com:4040',
  certificadoServiceEC2: 'https://ventaservice.tst.certicamara.com:5051',
  validacionIdentidadServiceEC2: 'https://ventaservice.tst.certicamara.com:4041',
  estadoTramiteServiceEC2: 'https://ventaservice.tst.certicamara.com:5055',
  tokenUrlWS: 'https://certiportaldesarrollo.auth.us-east-2.amazoncognito.com',
  tokenClientId: '3enknt0msqh6g62g5v06vhdqh0',
  tokenScope: 'CerCentral/cercentral',
  pasarela: 'P2P',
  tokenSecret: '1pf0f4timfnsr47keqergfmki7ngo76tnen53ed22oa2bl79pirp',
  direccionCerti: "Cr 7 #26-20 Piso 18-19, Edificios Seguros Tequendama",
  urlWebCerti: "https://web.certicamara.com/servicios/firmas-digitales/certificados",
  productoFormularioContacto: 2,
  urlVentaDigital: "https://ventadigital.tst.certicamara.com",
  accountValidacionFacial: '7b7dc00e-6f2c-4157-be7b-e46ca289d3f9',
  cipher: 'jnKk0Pz+QT91oryaFPJaryg3yNJ9GA3zbvegflf6H89X1eD/61xVEc4q2U/wlF09',
  recaptcha: {
    siteKey: '6LeMUeYpAAAAAG9aIjJSY__GV322KeizxvMpu5iM',
  },
  tokenOtp:'Xx6kndiJhIBi9NXuvOnq7doCWv6qC4WfKZc0oUM3tmWJpR38NFZlu3/78mLTP0IhM8TIHBmV/d91rgRcXY/lBbv14F2SQUm3tjGJGkjlnPI=',
  mailOtp:"operacion@certicamara.com",
  gatewayAWSOtp:'https://j942938lr5.execute-api.us-east-1.amazonaws.com',
  origen: "CANAL_VENTAS",
  apiEBiometrics: "https://api.ebiometrics.dev/v1/business/transactions",
  urlVideoReuso: "https://www.youtube.com/embed/c0f8qt7kuYs"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
