import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactenosComponent } from 'src/app/ui/components/contactenos/contactenos.component';
import { SspsComponent } from 'src/app/ui/components/ssps/ssps.component';
import { ConfirmationComponent } from 'src/app/ui/components/confirmation/confirmation.component';
import { SinPagoComponent } from 'src/app/ui/components/sinpago/sinpago.component';
import { ErrorComponent } from 'src/app/ui/components/error/error.component';
import { FinalizadoComponent } from 'src/app/ui/components/finalizado/finalizado.component';
import { SolicitudFinalizadaComponent } from 'src/app/ui/components/solicitudfinalizada/solicitudfinalizada.component';
import { SolicitudFaltantesComponent } from 'src/app/ui/components/solicitudfaltantes/solicitudfaltantes.component';
import { LoadingComponent } from 'src/app/ui/components/loading/loading.component';
import { MessageComponent } from 'src/app/ui/components/message/message.component';
import { ValidacionesComponent } from 'src/app/ui/components/validaciones/validaciones.component';
import { PersonaSolicitanteComponent } from 'src/app/ui/components/validaciones/persona-solicitante/persona-solicitante.component';
import { ValidacionIdentidadComponent } from 'src/app/ui/components/validacion-identidad/validacion-identidad.component';
import { AlertaValidaIdentidadComponent } from 'src/app/ui/components/alerta-valida-identidad/alerta-valida-identidad.component';
import { AlertaSalirValidaIdentidadComponent } from 'src/app/ui/components/alerta-salir-valida-identidad/alerta-salir-valida-identidad.component';
import { AlertaSalirMensajeValidaIdentidadComponent } from 'src/app/ui/components/alerta-salir-mensaje-valida-identidad/alerta-salir-mensaje-valida-identidad.component';
import { DescargaCertificadoComponent } from 'src/app/ui/components/descarga-certificado/descarga-certificado.component';
import { DescargaCertificadoModalComponent } from 'src/app/ui/components/descarga-certificado-modal/descarga-certificado-modal.component';
import { DescargaPfxComponent } from 'src/app/ui/components/descarga-pfx/descarga-pfx.component';
import { AceptarPrefacturaComponent } from 'src/app/ui/components/prefactura/aceptar-prefactura/aceptar-prefactura.component';
import { RechazarPrefacturaComponent } from 'src/app/ui/components/prefactura/rechazar-prefactura/rechazar-prefactura.component';
import { RevocarCertificadoComponent } from 'src/app/ui/components/revocar-certificado/revocar-certificado.component';
import { ConfirmacionAlertaComponent } from 'src/app/ui/components/confirmacion-alerta/confirmacion-alertacomponent';
import { DescargarFacturaComponent } from 'src/app/ui/components/descargar-factura/descargar-factura.component';
import { ShowOpenTabsComponent } from 'src/app/ui/components/showopentabs/show-open-tabs.component';
import { Subject } from 'rxjs';
import { PreinvoiceLoadingComponent } from 'src/app/ui/components/prefactura/preinvoice-loading/preinvoice-loading.component';
import { PreFactura, PreInvoiceParams } from '../interfaces/pre-invoice.interface';
import { PdfViewerComponent } from 'src/app/ui/components/pdf-viewer/pdf-viewer.component';
import { FinalizaRechazoComponent } from 'src/app/ui/components/prefactura/rechazar-prefactura/finaliza-rechazo/finaliza-rechazo.component';
import { EstadoTramiteComponent } from 'src/app/ui/components/estado-tramite/estado-tramite.component';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  activeDigital: any;
  modalLoading: any;
  modalspps: any;
  modalReporter: any;
  modalContacted: any;
  showMessage: any;
  modalOpenTabs: any;
  modalvisorPDF:any;

  private envios = new Subject<any>();
  // Flujos de cadenas observables
  // Observable de tipo string
  eventos$ = this.envios.asObservable();
  
  eventos(change: any) {
    this.envios.next(change);
  }

  constructor(private modalService: NgbModal) { }

  //TODO:modal para campo de detalles*/
  showFiels() {
    this.activeDigital = this.modalService.open(ValidacionesComponent, { backdrop: "static", size: "ms", centered: true });
  }

  showSolicitante() {
    this.activeDigital = this.modalService.open(PersonaSolicitanteComponent, { backdrop: "static", size: "ms", centered: true });
  }

  showFieldsValidacionIdentidad(urlRedireccionFlujoValida: any, botonFinalizaIdentidad: any) {
    this.activeDigital = this.modalService.open(ValidacionIdentidadComponent, { backdrop: "static", size: "ms", centered: true });
    this.activeDigital.componentInstance.urlRedireccionFlujoValida = urlRedireccionFlujoValida
    this.activeDigital.componentInstance.botonFinalizaIdentidad = botonFinalizaIdentidad

    return this.activeDigital
  }

  showFieldsRevocarCertificado() {
    this.activeDigital = this.modalService.open(RevocarCertificadoComponent, { backdrop: "static", size: "xl", centered: true });
  }

  showFieldsDescargarFactura() {
    this.activeDigital = this.modalService.open(DescargarFacturaComponent, { backdrop: "static", size: "xl", centered: true });
  }

  showFieldsEstadoTramite() {
    this.activeDigital = this.modalService.open(EstadoTramiteComponent, { backdrop: "static", size: "xl", centered: true });
  }

  showFieldsDescargaCertificado() {
    this.activeDigital = this.modalService.open(DescargaCertificadoComponent, { backdrop: "static", size: "lg", centered: true });
  }

  showFieldsDescargaPfx() {
    this.activeDigital = this.modalService.open(DescargaPfxComponent, { backdrop: "static", size: "lg", centered: true });
  }

  closesFiels() {
    if (this.activeDigital)
      this.activeDigital.close();
  }

  //TODO:modal loading
  showSpiner(mensaje: any) {
    this.modalLoading = this.modalService.open(LoadingComponent, { backdrop: "static", fullscreen: true, modalDialogClass: 'dark-modal' });
    this.modalLoading.componentInstance.mensaje = mensaje
  }

  showInvoiceSpinner(titulo: string){
    this.modalLoading = this.modalService.open(PreinvoiceLoadingComponent, { backdrop: "static", fullscreen: true, modalDialogClass: 'dark-modal' });
    this.modalLoading.componentInstance.title = titulo;
  }

  closesSpiner() {
    this.modalLoading?.close();
  }

  //TODO:modal ssps
  showSssps() {
    this.modalspps = this.modalService.open(SspsComponent, { backdrop: "static", size: "lg", centered: true });
  }

  closesSsps() {
    this.modalspps.close();
  }

  //TODO:Modal pventana de confirmacion
  showReporter(redirigirWeb: boolean) {
    this.modalReporter = this.modalService.open(ConfirmationComponent, { backdrop: "static", size: "sm", centered: true });
    this.modalReporter.componentInstance.redirigirWeb = redirigirWeb
  }

  showSinPago(titulo: any, mensaje: any,) {
    this.modalReporter = this.modalService.open(SinPagoComponent, { backdrop: "static", size: "sm", centered: true });
    this.modalReporter.componentInstance.titulo = titulo
    this.modalReporter.componentInstance.mensaje = mensaje
  }

  showAlertaValidarIdentidad(redirigirValidaIdentidad: boolean) {
    this.modalReporter = this.modalService.open(AlertaValidaIdentidadComponent, { backdrop: "static", size: "ms", centered: true });
    this.modalReporter.componentInstance.redirigirValidaIdentidad = redirigirValidaIdentidad
  }

  showAlertaSalirValidarIdentidad(mensajeSalir: any, cancelarValidacionFacial: boolean, usuarioValida: any,
     vista: any, urlRedireccionFlujoValida: any, botonFinalizaIdentidad: any) {
    this.modalReporter = this.modalService.open(AlertaSalirValidaIdentidadComponent, { backdrop: "static", size: "ms", centered: true });
    this.modalReporter.componentInstance.mensajeSalir = mensajeSalir
    this.modalReporter.componentInstance.cancelarValidacionFacial = cancelarValidacionFacial
    this.modalReporter.componentInstance.usuarioValida = usuarioValida
    this.modalReporter.componentInstance.vista = vista
    this.modalReporter.componentInstance.urlRedireccionFlujoValida = urlRedireccionFlujoValida
    this.modalReporter.componentInstance.botonFinalizaIdentidad = botonFinalizaIdentidad
  }

  showAlertaSalirMensajeValidarIdentidad(redirigirValida: boolean, urlRedireccionFlujoValida: any) {
    this.modalReporter = this.modalService.open(AlertaSalirMensajeValidaIdentidadComponent, { backdrop: "static", size: "sm", centered: true });
    this.modalReporter.componentInstance.redirigirValida = redirigirValida
    this.modalReporter.componentInstance.urlRedireccionFlujoValida = urlRedireccionFlujoValida
  }

  showError(mensaje: any, redirigir: boolean, titulo: any, identidadValidada: boolean,
     urlRedireccion: any, tituloBoton: any, redirigirExternal: boolean) {
    this.modalReporter = this.modalService.open(ErrorComponent, { backdrop: "static", size: "sm", centered: true });
    this.modalReporter.componentInstance.mensaje = mensaje
    this.modalReporter.componentInstance.redirigir = redirigir
    this.modalReporter.componentInstance.titulo = titulo !== null ? titulo : "¡Algo salió mal!"
    this.modalReporter.componentInstance.identidadValidada = identidadValidada
    this.modalReporter.componentInstance.urlRedireccion = urlRedireccion
    this.modalReporter.componentInstance.tituloBoton = tituloBoton !== null ? tituloBoton: "Cerrar"
    this.modalReporter.componentInstance.redirigirExternal = redirigirExternal
    return this.modalReporter
  }

  showConfirmacion(mensaje: any, titulo: any, tituloBoton: any, tituloBoton2: any) {
    this.modalReporter = this.modalService.open(ConfirmacionAlertaComponent, { backdrop: "static", size: "sm", centered: true });
    this.modalReporter.componentInstance.mensaje = mensaje
    this.modalReporter.componentInstance.titulo = titulo
    this.modalReporter.componentInstance.tituloBoton = tituloBoton
    this.modalReporter.componentInstance.tituloBoton2 = tituloBoton2
    return this.modalReporter
  }

  closeshowConfirmacion() {
    this.modalReporter.close();
  }

  showDescargaCertificado(redirigir: boolean) {
    this.modalReporter = this.modalService.open(DescargaCertificadoModalComponent, { backdrop: "static", size: "ms", centered: true });
    this.modalReporter.componentInstance.redirigir = redirigir
  }

  closesshowReporter() {
    this.modalReporter.close();
  }

  //TODO:modal modal contacto tecnico
  showContact(redirigir: boolean) {
    this.modalReporter = this.modalService.open(ContactenosComponent, { backdrop: "static", size: "ms", centered: true });
    this.modalReporter.componentInstance.redirigir = redirigir
  }

  closeContact() {
    this.modalReporter.close();
  }

  closeErrorAlert() {
    this.modalReporter.close();
  }

  //TODO:modal modal contacto tecnico
  showAlert(redirigir: boolean) {
    this.showMessage = this.modalService.open(MessageComponent, { backdrop: "static", size: "ms", centered: true });
    this.showMessage.componentInstance.redirigir = redirigir
  }

  showFinalizado(pagoComprobante: any) {
    this.showMessage = this.modalService.open(FinalizadoComponent, { backdrop: "static", size: "ms", centered: true });
    this.showMessage.componentInstance.pagoComprobante = pagoComprobante
  }

  showSolicitudFinalizada(redirigir: boolean) {
    this.showMessage = this.modalService.open(SolicitudFinalizadaComponent, { backdrop: "static", size: "ms", centered: true });
    this.showMessage.componentInstance.redirigir = redirigir
  }

  showSolicitudFaltantes() {
    this.showMessage = this.modalService.open(SolicitudFaltantesComponent, { backdrop: "static", size: "ms", centered: true });
  }

  closeAlert() {
    this.showMessage.close();
  }

  //Modales Prefactura
  showAceptarPrefactura(preInvoiceParams:PreInvoiceParams) {
    this.activeDigital = this.modalService.open(AceptarPrefacturaComponent, { backdrop: "static", size: "ms", centered: true });
    this.activeDigital.componentInstance.preInvoiceParams = preInvoiceParams;
  }

  closeAceptarPrefactura(){
    this.activeDigital.close();
  }

  showRechazarPrefactura(preInvoice:PreFactura) {
    this.activeDigital = this.modalService.open(RechazarPrefacturaComponent, { backdrop: "static", size: "ms", centered: true });
    this.activeDigital.componentInstance.preInvoiceData = preInvoice;
  }

  showFinishRechazarPrefactura(periodo:string){
    this.activeDigital = this.modalService.open(FinalizaRechazoComponent, { backdrop: "static", size: "ms", centered: true });
    this.activeDigital.componentInstance.preInvoiceData = periodo;
  }

  showOpenTabs(data: string) {
    this.modalOpenTabs = this.modalService.open(ShowOpenTabsComponent, { backdrop: "static", size: "sm", centered: true });
    this.modalOpenTabs.componentInstance.data = data;
  }
  closeTabs() {
    this.modalOpenTabs?.close();
  }

    //TODO:Muestra alerta en del visor PDF
  showpOpenPFD(data:string){
    this.modalvisorPDF = this.modalService.open(PdfViewerComponent, { backdrop: "static", size: "lg", centered: true });
    this.modalvisorPDF.componentInstance.data = data;
  }
  
  closePDF(){
    this.modalvisorPDF.close();
  }
}
