import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../../domain/servicios/log.service'
import { urlRequest } from 'src/assets/config/app.conf';
import { Observable, of } from 'rxjs';
import { SuccessResponse } from '../../domain/interfaces/response-base.interface';
import { GeneratePreInvoiceRequest, PreInvoiceDocument, PreInvoiceRequest, UpdatePreInvoiceRequest } from 'src/app/domain/interfaces/pre-invoice.interface';

@Injectable({
  providedIn: 'root'
})
export class PreInvoiceAdapterService {

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  urlRequest = urlRequest;

  constructor(private http: HttpClient, private logger: LogService) { }

  validateLink(preInvoiceId: string): Observable<SuccessResponse>  {
    this.logger.log("validar-link", preInvoiceId);
    let params = new HttpParams().set('id_prefactura', preInvoiceId);
    return this.http.get<SuccessResponse>(this.urlRequest.VALIDARLINKPREFACTURA, { params: params, ...this.httpOptions});
  }

  getPreInvoice(idPrefactura: string): Observable<SuccessResponse> {
    this.logger.log("consultar-prefactura", idPrefactura);

    let params = new HttpParams().set('id_prefactura', idPrefactura);
    return this.http.get<SuccessResponse>(this.urlRequest.CONSULTARPREFACTURA, { params: params, ...this.httpOptions});
  }

  acceptPreInvoice(body: any): Observable<SuccessResponse>  {
    this.logger.log("aprobar", body);
    return this.http.post<SuccessResponse>(this.urlRequest.APROBARPREFACTURA, body, this.httpOptions);
  }

  declinePreInvoice(body: any): Observable<SuccessResponse>  {
    this.logger.log("rechazar", body);
    return this.http.post<SuccessResponse>(this.urlRequest.RECHAZARPREFACTURA, body, this.httpOptions);
  }

  generatePreInvoiceDocument(body: PreInvoiceDocument): Observable<SuccessResponse>{
    this.logger.log("PDF-Prefactura", body);
    return this.http.post<SuccessResponse>(this.urlRequest.GENERARPDFPREFACTURA, body, this.httpOptions);
  }

  downloadPreInvoiceDocument(path: string):Observable<Blob>{
    let params = new HttpParams()
    .set('nombreBucket', 'fact-aut-tst')
    .set('key', path);
    
    return this.http.get(this.urlRequest.DOWNLOAD_FILE, { params, responseType: 'blob'});
  }

  createPreInvoice(body: PreInvoiceRequest): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(this.urlRequest.CREARPREFACTURA, body, this.httpOptions);
  }

  updatePreInvoice(body: UpdatePreInvoiceRequest): Observable<SuccessResponse> {
    return this.http.patch<SuccessResponse>(this.urlRequest.ACTUALIZARPREFACTURA, body, this.httpOptions);
  }

  generatePreInvoice(body: GeneratePreInvoiceRequest): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(this.urlRequest.REGENERARPREFACTURA, body, this.httpOptions);
  }

}
