import { Component, OnInit } from '@angular/core';
import { ComponentesService } from './domain/servicios/componentes.service';
import { SspsService } from './domain/servicios/ssps.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'certicámara';
  subscription!: Subscription;
  formsInit = true
  constructor(private components: ComponentesService,
    private ssps: SspsService, private router: Router
  ) {
    router.events.subscribe((val: any) => {
      if (val.url !== undefined && val.url.includes("/flujo"))
        this.formsInit = false
      if (val.url !== undefined && val.url === "/")
        this.formsInit = true
    });
  }
  ngOnInit(): void {
    this.ssps.checkIfFirstTab();
  }
}
